<template>

  <div class="w-full">
        <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Record</h1>
    <!-- Save Button -->
    <div class="mb-4">
      <button @click="saveNote" class="px-4 py-2 bg-blue-500 text-white rounded">Save</button>
    </div>

    <div class="flex space-x-6">

      
 <div class="w-1/2 ">
   <h2 class="text-2xl mb-4">Note:</h2>
        <quill-editor  ref="editor" v-model:content="content" content-type="html" theme="snow" @textChange="handleTextChange"/> 
   </div>
    <!-- Editor and Chat -->
 <h2 class="text-2xl mb-4">Project:</h2>


<div>
    <select v-model="selectedProject">
      <option v-for="project in projects" :key="project.id" :value="project.id">
        {{ project.name }}
      </option>
    </select>
</div>


      <!-- Chat -->
      <div class="w-1/2 h-full">
	   <h2 class="text-2xl mb-4">Chat:</h2>

	   <AssistSideChat ref="chatComponent"  @updateAction="onUpdateAction" />
	   


    
      </div>
    </div>


    </div>
</template>


<script>


    import { backend_get,backend_post } from '@/services/cognito-auth';
import { server } from '@/constants.js';
import { ref } from 'vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import '@vueup/vue-quill/dist/vue-quill.bubble.css';

import AssistSideChat from './AssistSideChat.vue'

var     totalTime = 25
var timeLeft = totalTime;
var timerRunning = true;

async function timer(params) {

   
    console.log("OLDTIMER")
    }

const cmds = {
	      'timer':timer}


function countWords(str) {
    // Remove any extra spaces and split the string into words
    const words = str.trim().split(/\s+/);
    // Filter out any empty strings that might result from multiple spaces
    const filteredWords = words.filter(word => word.length > 0);
    // Return the count of words
    return filteredWords.length;
}

export default {
  components: {
      QuillEditor,
      AssistSideChat
  },
    data() {
	return {
	    projects: [],
	    	  selectedMode: '',
       }
	},
    
    setup() {
	const content = ref('');
	console.log("SETUP")
	return content
    },
    methods: {
		async  onUpdateAction(cmd) {
      // This method will be triggered when the 'action-occurred' event is emitted by AssistSideChat
		    console.log('Action occurred in AssistSideChat!:',cmd);

		     console.log(cmd.functionName in cmds)
	    if (cmd.functionName in cmds) {
		console.log("DISPATCH")
		cmds[cmd.functionName](cmd.args)
		}

		},
	async saveNote() {

	    console.log("SAVE")

	    console.log(this.content)

	     let message = {
		 message: {text: this.content },
		 project_id: 0
	     }
	    
	    let response = await backend_post(`${server}/messages/new`, message)
	    console.log(response.data);	    

	    this.$refs.editor.setHTML("")
	    this.$refs.chatComponent.clearMessages();

	},
	handleTextChange (delta, oldContents, source) {
//	    console.log("TEXT Changed",this.content)
	    console.log(countWords(this.content))
	    }
    },
    async mounted() {
	console.log("Mounted")
	//projects

	let r = await backend_get(`${server}/projects/3`)


	console.log(r.data[0])


	this.projects =[
      { id: 1, name: 'Project A' },
      { id: 2, name: 'Project B' },
      { id: 3, name: 'Project C' }]
	
	console.log("WTF")	
	//this.projects.push(r.data[0])
	}
	
};
</script>

<style scoped>
.quill-container {
  height: calc(100vh - 3rem); /* Adjust based on the height of your button and desired spacing */
}
</style>
