<template>

  <div class="w-full">
        <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Record</h1>
   

    <div class="flex space-x-6">

      
 <div class="w-1/2 ">
   <h2 class="text-2xl mb-4">Notes:</h2>
      

    <!-- Editor and Chat -->

 <table class="min-w-full bg-white border border-gray-300">
    <thead>
        <tr>
            <th class="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider" v-for="header in headers" :key="header">{{ header }}</th>

        </tr>
    </thead>
    <tbody>
      <tr v-for="note in notes" :key="note.id">
	
	 <td class="py-2 px-4 border-b border-gray-300">
    

<a
      :href="`/assist/topic/${note.id}`"
      target="_blank"
      rel="noopener noreferrer"
      class="text-gray-700 hover:text-blue-600 transition-colors"
    >


  <div class="line-clamp-4" v-html="note.text"></div>
  <!--
      </router-link>
    -->
    </a>
            </td>
           
        </tr>
    </tbody>
</table>

</div>

      <!-- Chat -->
      <div class="w-1/2 h-full">
	   <h2 class="text-2xl mb-4">Chat:</h2>

    <AssistSideChat @updateAction="onUpdateAction" />

    
      </div>
    </div>


    </div>
</template>


<script>

import { backend_get,backend_post } from '@/services/cognito-auth';
import { server } from '@/constants.js';
import { ref } from 'vue';

import AssistSideChat from './AssistSideChat.vue'


function addTailwindClassesToHtml(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Add classes to <p> tags
  doc.querySelectorAll('p').forEach(p => {
    p.classList.add('mb-4');  // Example: Add margin-bottom
  });

    //list-decimal
    
    // Add classes to <li> tags
    /*
  doc.querySelectorAll('li').forEach(li => {
    li.classList.add('list-decimal', 'ml-5');  // Example: Add list style and margin-left
    });
    */

      doc.querySelectorAll('ol').forEach(ol => {
    ol.classList.add('list-decimal');  // Example: Set list style position inside
      });
    
  // Add classes to <ul> tags
  doc.querySelectorAll('ul').forEach(ul => {
    ul.classList.add('list-disc');  // Example: Set list style position inside
  });

  // Add classes to <h1> tags
  doc.querySelectorAll('h1').forEach(h1 => {
    h1.classList.add('text-4xl', 'font-bold', 'mb-4');  // Example: Set font size, font weight, and margin-bottom
  });

  // Add classes to <h2> tags
  doc.querySelectorAll('h2').forEach(h2 => {
    h2.classList.add('text-3xl', 'font-semibold', 'mb-3');  // Example: Set font size, font weight, and margin-bottom
  });

  // Convert the modified document back to a string
  return doc.body.innerHTML;
}



async function  recent(params) {

    console.log("recent")

    let offset = 0

    return await backend_get(`${server}/messages/recent?offset=${offset}`)
	  
}


async function find(params) {

    //TODO: do regular and semantic search
    console.log("find:",params)

     let offset = 0

	    var encodedParam = encodeURIComponent(params);
return await backend_get(`${server}/messages/search?term=${encodedParam}&offset=${offset}`)
    
}

			 
async function ask(params) {

    }


async function merge(params,notes) {
    console.log("Merge")

    const checkedNotes = notes.filter(note => note.isChecked);

    console.log(checkedNotes)
    
    }

const cmds = {'find': find,
	      'recent': recent,
	      'ask': ask,
	     'merge':merge}


/*

  Merge Button: send all ids to a new Record, where you can edit and save.

  The merged messages are archived but not changed and a new msg is created with the merge and the user can edit.

  */

export default {
  components: {
      AssistSideChat
  },
    data() {
	return {
	    headers: ['Text'],
	    notes: [],
	}

    },

    methods: {
	async load_topics() {

	    	  let r =  await backend_get(`${server}/projects/3`)
	  
	  console.log(r)


	      r.data.forEach((p) => {
		  console.log("NOTE:",p)

		  this.notes.push({ id: p.id, text:p.name})
	      })

	},
	async  onUpdateAction(cmd) {
      // This method will be triggered when the 'action-occurred' event is emitted by AssistSideChat
	     console.log('Action occurred in AssistSideChat!:',cmd);

	    console.log(cmd.functionName in cmds)
	    if (cmd.functionName in cmds) {
		console.log("DISPATCH")
		let messages = await cmds[cmd.functionName](cmd.args,this.notes)

		if (messages) {
		console.log(messages)
		 this.notes = []
	     messages.data.messages.forEach((note) => {
		  console.log("NOTE:",note)

		 this.notes.push({ id: note.id, text:addTailwindClassesToHtml(note.message.text)})
	     })
		    }
		/*
	    

	    
		  */
	    }
      // Reload your data or perform other actions here
    },

    },
      async mounted() {
	  console.log("Mounted")
	  this.load_topics()

    },
	
};
</script>

<style scoped>
.quill-container {
  height: calc(100vh - 3rem); /* Adjust based on the height of your button and desired spacing */
}
.content {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px; /* or whatever height you want to set */
}

.text.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* truncate to 4 lines */
  -webkit-line-clamp: 3;
}
</style>
