<template>
  <div>
    <h1>Gen Doc</h1>

    <button @click="handleButtonClick" class="bg-blue-500 text-white p-2 rounded ml-2">Generate</button>

 <pre class="whitespace-pre-wrap break-words">{{ documentSummary }}</pre>
  </div>
</template>

<script>
import axios from 'axios';
import { get_token,backend_get,backend_post } from '@/services/cognito-auth';
import { server } from '@/constants.js';
  
export default {
   props: {
    projectId: {
      type: String, // or Number, depending on your use case
      required: true
    }
   },
  data() {
    return {
	inputValue: '',
	documentSummary: ''
    };
  },
  methods: {
    async handleButtonClick() {
      

	console.log(`GEN DOC: ${this.projectId}`)
	/*
	let r = await backend_post(`${server}/docs/gen/${this.projectId}`)
	console.log("Get results")
	console.log(r.data)
	this.documentSummary = r.data.response
	*/
    }
  }
}
</script>

<style scoped>
</style>
