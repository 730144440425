
<template>
  <div class="flex">
    <CardsSideNav @changeComponent="changeComponent" />
<router-view></router-view>
  </div>
</template>

<script>
  import CardsSideNav from './CardsSideNav.vue'


export default {
  components: {
    CardsSideNav,

  },
  data() {
    return {
      
    };
  },
  methods: {
    
  }
}
</script>
<style>
  @import url("../assets/output.css");

</style>
