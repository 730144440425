<template>
  <div class="flex h-screen">

  </div>
</template>

<script>


  export default {
     props: {
    projectId: {
      type: String, // or Number, depending on your use case
      required: true
    }
   },
  name: 'ProjectSettings',
  components: {

  },
  	mounted() {
	    console.log("Mounted",this.projectId)
	    console.log(this.projectId)
	},
}
</script>

<style>


</style>
