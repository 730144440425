<template>
  <div class="quiz-builder">
    <h1>Create a Quiz</h1>

     <button @click="save" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Save
     </button>
     
    <!-- Quiz Name Input -->
    <div class="mb-4">
      <label for="quizName" class="block text-sm font-medium text-gray-700">Quiz Name</label>
      <input
        type="text"
        id="quizName"
        v-model="quizName"
        placeholder="Enter quiz name"
        class="mt-1 p-2 w-full border rounded-md"
      />
    </div>

   <table class="min-w-full bg-white border border-gray-300">
      <thead>
        <tr>
          <th class="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider">Question</th>
          <th class="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider">Answer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(question, index) in questions" :key="index">
          <td class="py-2 px-4 border-b border-gray-300">{{ question.question }}</td>
          <td class="py-2 px-4 border-b border-gray-300">{{ question.answer }}</td>
        </tr>
      </tbody>
    </table>
    
    <!-- Questions List -->

  <div class="mb-2">
    <label  ref class="block text-sm font-medium text-gray-700">Question </label>
    <input
v-model="inputQ"
      placeholder="Enter question"
      class="mt-1 p-2 w-full border rounded-md"
    />
  </div>

  <div>
    <label   class="block text-sm font-medium text-gray-700">Answer </label>
    <input
 v-model="inputA"
      placeholder="Enter answer"
      class="mt-1 p-2 w-full border rounded-md"
    />
  </div>


    <!-- Add Question Button -->
    <button @click="addQuestion" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Add Question
    </button>
  </div>
</template>

<script>
import { get_token,backend_post, backend_upload } from '@/services/cognito-auth';
import { server } from '@/constants.js';

export default {
  data() {
    return {
      quizName: '',
	questions: [],
	inputQ: '',
	inputA: ''
    };
  },
  methods: {
    addQuestion() {
	this.questions.push({ question: this.inputQ, answer: this.inputA , type:'input'});
	this.inputA = ""
	this.inputQ = ""
},
      async save() {
	  console.log("SAVE QUIZ")

	  let quiz = {name: this.quizName,
		      questions: this.questions}

	  console.log(quiz)

	  let r = await backend_post(`${server}/quiz/new`,quiz)
	  console.log(r)

      }
  }
};
</script>

<style scoped>
/* You can add styles specific to this component here */
</style>
