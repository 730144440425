<template>

  <div class="w-full">
        <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Edit</h1>
    <!-- Save Button -->
    <div class="mb-4">
      <button @click="saveNote"
	      :disabled="isDisabled"
      :class="isDisabled ? 'px-4 py-2 bg-gray-500 cursor-not-allowed text-white rounded' : 'px-4 py-2 bg-blue-500 text-white rounded'"
	      >Save</button>

      <button @click="preview"

      class="px-4 py-2 bg-blue-500 text-white rounded"
	      >Preview</button>
    </div>

    <div class="flex space-x-6">

      
 <div class="w-1/2 ">
   <h2 class="text-2xl mb-4">Note:</h2>
   <quill-editor  ref="editor" v-model:content="content" content-type="html" theme="snow"
		  @textChange="handleTextChange"/> 
   </div>
    <!-- Editor and Chat -->



      <!-- Chat -->
      <div class="w-1/2 h-full">
	   <h2 class="text-2xl mb-4">Chat:</h2>

    <AssistSideChat ref="chatComponent"/>

    
      </div>
    </div>


    </div>
</template>


    <script>
    import { backend_get,backend_post } from '@/services/cognito-auth';
import { server } from '@/constants.js';
import { ref } from 'vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import '@vueup/vue-quill/dist/vue-quill.bubble.css';

import AssistSideChat from './AssistSideChat.vue'


export default {
  components: {
      QuillEditor,
      AssistSideChat
  },
    props: {
	noteId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },    
    data() {
	return {
	note: null,
	    isDisabled: true,
	    textchanged: 0
	    }

    },
    
    setup() {
	const content = ref('');
	console.log("SETUP")
	return content
    },
    methods: {
	async saveNote() {

	    console.log("SAVE")

	    console.log(this.content)

	     let message = {
		 message: {text: this.content },
	     }


	    let response = await backend_post(`${server}/messages/edit/${this.note.id}`, message)
	    console.log(response.data);	    
	    //redirect 
	    this.isDisabled = true


	},
	 handleTextChange (delta, oldContents, source) {
//	     console.log("TEXT Changed",this.textchanged)

	     if (this.textchanged > 0)
		 this.isDisabled = false

	     	     this.textchanged += 1

	 },

	preview() {
	    console.log("Preview")
	    	      this.$router.push(`/assist/preview/${this.noteId}`)
	    }
    },
    async mounted() {
	console.log("MOUNTED")
	//load message/note

	let url = `${server}/messages/get/${this.noteId}`
	let r  = await backend_get(url)

	this.note = r.data
	console.log(r)
	this.$refs.editor.setHTML(this.note.message.text)
    }

	
};
</script>

<style scoped>
.quill-container {
  height: calc(100vh - 3rem); /* Adjust based on the height of your button and desired spacing */
}
</style>
