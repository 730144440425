<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>


<style>
  @import url("./assets/output.css");

</style>
