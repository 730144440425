<template>

    <!-- Chat History -->
    <div class="pr-4">
      <div v-for="(message, index) in messages" :key="index" class="mb-4">
        <div v-if="message.type === 'received'" class="text-left">
          <div class="inline-block bg-blue-200 rounded px-4 py-2">
            {{ message.content }}
          </div>
        </div>
        <div v-else class="text-right">
          <div class="inline-block bg-green-200 rounded px-4 py-2">
            {{ message.content }}
          </div>
        </div>
      </div>
    </div>

    <!-- Message Input -->
    <div class="border-t p-4 bg-white">
      <textarea
        v-model="newMessage"
        @keyup.enter="sendMessage"
        class="w-full rounded p-2 border"
        placeholder="Type your message..."
      ></textarea>
    </div>

</template>

<script>
    import { backend_get,backend_post } from '@/services/cognito-auth';
import { server } from '@/constants.js';

  function extractURL(str) {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    // Find URLs in the string
    const urls = str.match(urlRegex);
    
    // If URLs are found, return the first one. Otherwise, return null.
    return urls && urls.length > 0 ? urls[0] : null;
}


function parseCommand(input) {
  // Regular expression to match patterns like "/command arg1 arg2 arg3"
  const regex = /^\/(\w+)\s+(.*)/;
  const match = input.match(regex);

  if (match) {
    const functionName = match[1];
    const args = match[2];

    return {
      functionName,
      args
    };
  } else {
    return null
  }
}

var     totalTime = 25
var timeLeft = totalTime;
var timerRunning = true;

async function timer(params) {

   
    console.log("TIMER")
    }

const cmds = {
	      'timer':timer}



function findIndexById(array, idToFind) {
  const index = array.findIndex((item) => item.id === idToFind);
  return index; // Will be -1 if not found
}

export default {
    emits: ["updateAction"],
    
  data() {
    return {
      newMessage: "",
      messages: [
      ],

     inputMinutes: 1,
      endTime: null,
      timeLeft: null,
      timerRunning: false,
	timerInterval: null,
	timerCount: 0
    };
  },
 
    methods: {
	clearMessages() {
	    this.messages = []; // this will clear the messages array
	},
	formattedTimeLeft() {
	    if (this.timeLeft === null) {
		return '0:00';
	    }
	    const minutes = Math.floor(this.timeLeft / 60);
	    const seconds = this.timeLeft % 60;
	    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
	},

	startTimer() {
	    const now = Date.now();
      this.endTime = now + this.inputMinutes * 60 * 1000;
      this.timerRunning = true;
      this.updateTimeLeft();

      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }

	    this.timerInterval = setInterval(this.updateTimeLeft, 200); // update more frequently for a smoother countdown
	    
	},

	updateTimeLeft() {
	    const now = Date.now();
	    this.timeLeft = Math.max(0, Math.ceil((this.endTime - now) / 1000));

	    let id = findIndexById(this.messages,`timer-${this.timerCount}`)
//	    console.log("ID:",id)

	    this.messages[id].content = this.formattedTimeLeft()
	    if (this.timeLeft === 0) {
		this.stopTimer();
		alert('Time is up!');
	    }
    },
    stopTimer() {
      clearInterval(this.timerInterval);
      this.timerRunning = false;
      this.timerInterval = null;
	this.timeLeft = null;
	this.timerCount += 1;
    },
    async sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.messages.push({ type: "sent", content: this.newMessage.trim() });
	 
	    //check if link and get summary
	  console.log("WTF:",this.newMessage)
	  

	  let command = parseCommand(this.newMessage)

	  if (command) {
	      console.log("Command: ",command)

	      if (command.functionName == 'timer') {
		  console.log("TIMER")
		  this.inputMinutes = command.args
		  
		  this.messages.push({ type: "send", content:this.formattedTimeLeft(), id:`timer-${this.timerCount}` });
		  this.startTimer()
		  
	      }
	      else
		  this.$emit('updateAction',command)
	  }
	  
	  let url = extractURL(this.newMessage)

	   this.newMessage = "";
	    if (url) {
		console.log("URL IN MESSAGE:",url)


		
		let data = {'url':url}
			    
		let r = await backend_post(`${server}/messages/link/summary`,data)
		console.log("RETURN:",r)

		this.messages.push({ type: "received", content:r.data.summary });
//		this.messages.push({ sender: 'Bot', time: 'Just now', text: r.data.summary });


		
	    }


	  
      }
    },
  },
};
</script>

<style scoped>
/* You can add any additional styles here if needed */
</style>
