<template>
  <div class="quiz-builder">
    <h1>Quiz Edit</h1>

     <button @click="save" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Save
     </button>
     
    <!-- Quiz Name Input -->
    <div class="mb-4">
      <label for="quizName" class="block text-sm font-medium text-gray-700">Quiz Name</label>
      <input
        type="text"
        id="quizName"
        v-model="quizName"
        placeholder="Enter quiz name"
        class="mt-1 p-2 w-full border rounded-md"
      />
    </div>

   <table class="min-w-full bg-white border border-gray-300">
      <thead>
        <tr>
          <th class="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider">Question</th>
          <th class="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider">Answer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(question, index) in questions" :key="index">
          <td class="py-2 px-4 border-b border-gray-300">{{ question.question }}</td>
          <td class="py-2 px-4 border-b border-gray-300">{{ question.answer }}</td>
        </tr>
      </tbody>
    </table>
    
    <!-- Questions List -->

  <div class="mb-2">
    <label  ref class="block text-sm font-medium text-gray-700">Question </label>
    <input
v-model="inputQ"
      placeholder="Enter question"
      class="mt-1 p-2 w-full border rounded-md"
    />
  </div>

  <div>
    <label   class="block text-sm font-medium text-gray-700">Answer </label>
    <input
 v-model="inputA"
      placeholder="Enter answer"
      class="mt-1 p-2 w-full border rounded-md"
    />
  </div>

<div>
    <label   class="block text-sm font-medium text-gray-700">Explanation </label>
    <input
 v-model="inputE"
      placeholder="Enter answer"
      class="mt-1 p-2 w-full border rounded-md"
    />
  </div>

    <!-- Add Question Button -->
    <button @click="addQuestion" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Add Question
    </button>
  </div>
</template>

<script>
import { get_token,backend_post, backend_get} from '@/services/cognito-auth';
import { server } from '@/constants.js';

export default {
    props: {
	quizId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },

  data() {
    return {
      quizName: '',
	questions: [],
	inputQ: '',
	inputA: '',
	inputE: '',
    };
  },
  methods: {
    addQuestion() {
	this.questions.push({ question: this.inputQ, answer: this.inputA , type:'input', explain: this.inputE});
	this.inputA = ""
	this.inputQ = ""
		this.inputE = ""
},
      async save() {
	  console.log("SAVE QUIZ")

	  let quiz = {name: this.quizName,
		      questions: this.questions}

	  console.log(quiz)

	  let r = await backend_post(`${server}/quiz/edit/${this.quizId}`,quiz)
	  console.log(r)

      }
  },
    async mounted() {
	console.log("Mounted:",this.quizId)
	let url = `${server}/quiz/get/${this.quizId}`
	let r  = await backend_get(url)
	console.log(r.data.quiz)

	this.quizName = r.data.quiz.name
	r.data.quiz.questions.forEach((qa) => {
	    console.log(qa)
	    this.questions.push({ question: qa.question, answer: qa.answer , type: qa.type, explain: qa.explain});
	})

	
    }
};
</script>

<style scoped>
/* You can add styles specific to this component here */
</style>
