<template>
  <div class="w-full">
    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Edit Card</h1>
     <!-- Save Button -->
    <div class=" mt-6">
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="save">Save</button>
           



   
    </div>

    <div class="flex space-x-6">
      <!-- Text Areas (Left Side) -->
      <div class="w-1/2">
        <h2 class="text-2xl mb-4">Front:</h2>
        <textarea class="w-full border rounded mb-4" v-model="newFront" rows="6" placeholder="Enter front of card here..."></textarea>

        <h2 class="text-2xl mb-4">Back:</h2>
        <textarea class="w-full border rounded" v-model="newBack" rows="6" placeholder="Back of card, first line will be converted to audio..."></textarea>
      </div>

      <!-- Drawing Components (Right Side) -->
      <div class="w-1/2">
        <!-- Drawing Controls -->
    <h2 class="text-2xl mb-4">Draw:</h2>
    <button    	      :disabled="isDisabled"   :class="isDisabled ? 'px-4 py-2 bg-gray-500 cursor-not-allowed text-white rounded' : 'px-4 py-2 bg-blue-500 text-white rounded'"
@click="gen">Gen</button>
    
     <pulse-loader :loading="isLoading"  :color="colorl" :size="size"></pulse-loader>
     <input 
        type="text" 
        v-model="style" 
        class="border rounded p-2 w-full" 
        placeholder="Enter Style"
    />
    
      <input type="file" @change="handleImageUpload" />
        <div class="flex items-center space-x-4 mb-4">
          <!-- Color Picker -->
          <Compact @color-changed="updateColor" class="flex-shrink-0"></Compact>

          <!-- Draw Mode -->
          <div class="flex flex-col items-start">
            <select v-model="selectedMode" @change="onModeChange" class="border rounded px-2 py-1">
              <option value="draw">Draw</option>
              <option value="fill">Fill</option>
              <option value="erase">Erase</option>
            </select>
          </div>
	  <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" @click="clear">Clear</button>

          <!-- Pen Size -->
          <div class="flex flex-col items-center space-y-2">
            <div class="w-10 h-10 border rounded-full flex items-center justify-center">
              <div :style="{ width: penSize + 'px', height: penSize + 'px' }" class="bg-black rounded-full"></div>
            </div>
            <input type="range" v-model="penSize" min="1" max="38" class="w-24" @input="onPenSizeChange">
          </div>
        </div>

        <!-- Canvas -->
        <div class="flex justify-center items-center border p-2 rounded h-[512px] w-full">
          <canvas ref="sketchpad" width="500" height="500"></canvas>
	 
        </div>
      </div>
    </div>

   
  </div>
</template>


<script>


import axios from 'axios';
import { get_token,backend_post,backend_get,backend_upload } from '@/services/cognito-auth';
import * as zeit from '@/services/zeit.js';
import { server } from '@/constants.js';
import { Atrament } from 'atrament';
import * as dermotor from '@/services/dermotor.js';

//import { ColorPicker } from 'vue-accessible-color-picker'
//import { Compact } from '@ckpack/vue-color'
import Compact from './ColorPicker.vue'

//Vue.component(Verte.name, Verte);
import { ref } from 'vue';



import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export function toPlaintext(delta) {
    console.log("DELTA:",delta)
    return delta.reduce(function (text, op) {
	if (!op.insert) return ''//throw new TypeError('only `insert` operations can be transformed!');
	if (typeof op.insert !== 'string') return text + ' ';
	return text + op.insert;
    }, '');
};


async function save_card(front,back,canvas,card) {
    console.log("SAVE CARD")

        canvas.toBlob(async function(blob) {

	    const formData = new FormData();
	    
	    let newFront = {ops: [{insert:front}]}
	    let newBack = {ops: [{insert:back}]}

	    let updated_card = { ...card }
	    updated_card.jsondata.front = newFront
	    updated_card.jsondata.back = newBack


	    formData.append('file', blob, card.jsondata.key);
	    let ur = await backend_upload(`${server}/upload/card/${updated_card.id}`,formData)
	    console.log(ur.data.path)

	    updated_card.jsondata.path = ur.data.path

	    updated_card.jsondata = JSON.stringify(updated_card.jsondata)
	    delete updated_card.url
	    console.log(updated_card)
	    
	    let uc = await backend_post(`${server}/cards/edit/${updated_card.id}`,updated_card)

	    console.log(uc)


	})
  /*
  let due_date = zeit.get_date_string(zeit.toLocalTime(zeit.todays_date()))
		
		new_card.jsondata = JSON.stringify({front:front_text,back:back_text,path:ur.path,scores:[],key:key})
		new_card.due_date = due_date

		console.log("NC:",new_card)
		backend.backend_post(`${server}/cards/add`,new_card, (r) => {

		    console.log("Card Added")
		    f()

  })
  */
}






export default {
    name: 'CardEdit',
    props: {
	cardId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },
    data() {
      return {
	  newFront: "",
	  newBack: "",
	  color: {hex: '#ff0000'},
	  sketchpad: null,
	  card: null,

	  selectedMode: 'draw',
	  penSize: 10, // Initial pen size
	  isLoading: false,
	  colorl: '#FFA500',
	  genDisabled: false,
	  isDisabled: false,
	  style: ""

      // Your data properties go here
    };
  },
    components: {
	Compact,
	PulseLoader

	},

  computed: {
    // Your computed properties go here
  },
    methods: {
	async load_image() {
	const canvas = this.$refs.sketchpad;
	this.sketchpad = new Atrament(canvas);
	console.log("W:",this.cardId)

	let url = `${server}/cards/get/${this.cardId}`
	let r  = await backend_get(url)
	this.card = r.data
	console.log(r.data)
	console.log(this.card)

	this.newFront = toPlaintext(this.card.jsondata.front.ops)
	this.newBack = toPlaintext(this.card.jsondata.back.ops)

	console.log("HAS Image")

	let path = encodeURIComponent(this.card.jsondata.path)
	try {
	    let image_b64  = await backend_get(`${server}/cards/image/${this.card.id}`)
	    console.log(image_b64)
	    image_b64 = 'data:image/png;base64,' + image_b64.data.image

	    var ctx = canvas.getContext("2d");
	    dermotor.draw_image(ctx,image_b64,500,500)

	}
	catch (error) {
	    console.log(error)
	    }
	},
	async gen() {
	    console.log("GEN")
	    
	    this.isLoading = true;
	    this.genDisabled = true;
	    this.isDisabled = true

	    console.log(this.style)
	    let artist = "Vermeer"
	    let style = ""
	    if (this.style != "")
		style = `In the style of ${this.style}`
	    
	    let prompt = `The following is text for a flash card, please use the text to make a memorable image that can be used to help remember this flash card, but please do not include any text in the image. ${style}: ${this.newBack}`
	    console.log(prompt)
	    var encodedParam = encodeURIComponent(prompt)
	    let url = `${server}/cards/gen/${this.cardId}?term=${encodedParam}`
	    let r  = await backend_get(url)
	    console.log(r)

	    await this.load_image()

	    this.isLoading = false;
	    this.genDisabled = false;
	    this.isDisabled = false

	    
	  /*
	    const canvas = this.$refs.sketchpad;
	    var ctx = canvas.getContext("2d");

	    dermotor.load_image(ctx,canvas,t)
	    */
	    
	},
	
	updateValue (value) {
	    console.log("VALUE")
	    //this.colors = value
	},
	onModeChange() {
      // Callback function when the mode is changed
      console.log("Selected Mode:", this.selectedMode);
	    // You can add more logic here or emit this to a parent component if needed
	     this.sketchpad.mode = this.selectedMode
	    //sketchpad.weight = parseFloat(event.target.value);
	},
	onPenSizeChange(event) {
	    console.log("Pen size changed to:", this.penSize);

	    let ps = this.penSize;
	    this.sketchpad.weight = parseFloat(ps)
	},	
	 updateColor(newColor) {
	     this.color = newColor;
	     console.log(newColor)

	     //console.log(newColor.cssColor)
	     this.sketchpad.color = newColor.hex
	 },
	async save() {
	    console.log("New card")
	    console.log(this.newFront,this.newBack)
	    const canvas = this.$refs.sketchpad;
	    await  save_card(this.newFront,this.newBack,canvas,this.card)
	    this.$router.push(`/cards`)
	},
	clear() {
	    this.sketchpad.clear();
	},
	handleImageUpload(event) {
	    const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            // Resize the canvas to fit the image
            //this.$refs.sketchpad.width = img.width;
              //this.$refs.sketchpad.height = img.height;
	      const canvas = this.$refs.sketchpad;
	      let ctx  = canvas.getContext('2d');

	      const hRatio = canvas.width / img.width;
        const vRatio = canvas.height / img.height;
        const ratio = Math.min(hRatio, vRatio);

        // Calculate the scaled dimensions
        const centerShift_x = (canvas.width - img.width * ratio) / 2;
        const centerShift_y = (canvas.height - img.height * ratio) / 2;
        // Draw the scaled image on the canvas
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
	      
	      
            //ctx.drawImage(img, 0, 0);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
	    }
    // Your methods go here
  },
    async mounted() {

	this.load_image()
    // Code to run when the component is mounted
  },
};
</script>

<style scoped>
/* Your CSS goes here */
</style>
