

export function convert_hhmmss(hms){

    //    var hms = '02:04:33';   // your input string
    var a = hms.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return  (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
}





function days_minus(start_date,days) {
    var newDate = new Date(start_date.getTime())
    newDate.setDate(newDate.getDate() - days)
   
    return newDate
    
}

function days(start,end) {

    let r = []
    
    let c = start

    while (c.getTime() != end.getTime()) {
	r.push(c)
	c = plus_days(c,1)
    }

    return r
}

function days_between(a,b) {

        return Math.round((b-a)/(1000*60*60*24))
}


function treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

function daysBetween(startDate, endDate) {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

//array of all dates today + 30d



export function parse_date(d) {
    return new Date(Date.parse(d))
}

function minus() {
}
const ops = {'-':minus}
const ind = {'today':todays_date}
const funcs = {'days':{'f':days,a:2}}


//tests
const t1 = '3 + 5'


function get_tokens(s) {

    return s.split(/[\s,()]+/)
}

function nn() {
    return {'l':null,'r':null,v:null}
}

function is_lit(t) {
    //30d
  const parsed = parseInt(t, 10);
    if (isNaN(parsed)) return false
    
    return true
}

//https://khan4019.github.io/front-end-Interview-Questions/bst.html
function compile(ts) {
    //turn the tokens into parse tree

    let root = nn()
    let cr = null
    /*
    ts.forEach((t) => {

	let n = nn()

	if is_iden(t) {
	    n.value = t
	    
	}
	   
	
	
    })
*/
    return root



}


function ex(ast) {

//    if (ts.length == 0) return


    /*
    if (ts[0] != '(')
	
	if (ts[0] in funcs)
	    return funcs[ts[0]]()
*/
    
}

function z_exec(s) {

    const ts = get_tokens(s)

    console.log(ts)
    
    return ex(compile(ts))
}




export function toLocalTime(time) {
  var d = new Date(time);
  var offset = (new Date().getTimezoneOffset() / 60) * -1;
  var n = new Date(d.getTime() + offset);
  return n;
};

export function todays_date() {
    let d = new Date()
    //let d = toLocalTime(new Date())
    d.setHours(0,0,0,0)
    return d
}

export function get_date_string(d) {
    //this this return utc
    return d.toISOString().slice(0,10)
}

export function plus_days(start_date,days) {
    let newDate = start_date
    newDate.setDate(newDate.getDate() + days)
   
    return newDate
    
}



function addHours(d,h) {
  d.setTime(d.getTime() + (h*60*60*1000));
    return d
}

function yearsDiff(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    let yearsDiff =  date2.getFullYear() - date1.getFullYear();
    return yearsDiff;
}

function months_until(m,y) {



    let date1 = todays_date()
    
    
   let date2 = new Date(y, m, 1, 0, 0, 0, 0);
  let years = yearsDiff(date1, date2);
  let months =(years * 12) + (date2.getMonth() - date1.getMonth()) ;
  return months;


}


export function days_until(d,m,y) {



    let date1 = todays_date()
    
    
    let date2 = new Date(y, m-1, d, 0, 0, 0, 0);
    console.log(date2)
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;

}


function get_month_year_str(dts) {

    let dt = datefns.parseISO(dts)

    return `${dt.getUTCMonth() + 1}/${dt.getUTCFullYear()}`

}

function is_today(dts) {

    let dt = datefns.parseISO(dts)

    return datefns.isToday(dt)
}


function is_same_month(as,b) {

    let dt = datefns.parseISO(as)

    return datefns.isSameMonth(dt,b)
}

function sort_dates(dtss) {

    let ds = []

    for (d of dtss) {
	ds.push(datefns.parseISO(d))
    }

//    console.log(ds)

    return ds
}


function count_gaps(dtss) {

    let sorted_dates = sort_dates(dtss)
    let gaps = []

    let sd = sorted_dates[0]
    let dc = 0
    
    for (const d of sorted_dates.slice(1)) {
//	console.log("DIFF:",sd,d)
	if (!datefns.isSameDay(sd,d)) {
//	    console.log("NOTSAMES")
	    gaps.push(datefns.differenceInDays(d,sd))
	}
	sd = d
    }

    return gaps
}

function count_sameday(dtss) {

    let sorted_dates = sort_dates(dtss)
    let sc = dtss.length > 0 ? 1 : 0
    let sd = sorted_dates[0]
    for (const d of sorted_dates.slice(1)) {
	if (!datefns.isSameDay(sd,d)) {
	    sc += 1
	}
	sd = d
    }


    return sc
}

function count_sameday_values(dtvs) {

    let dv = {}

    for (const v of dtvs) {
//	console.log(v.dt)
	let dt = datefns.parseISO(v.dt)
	let d = datefns.getDate(dt)
	if (d in dv)
	    dv[d] += parseInt(v.value)
	else 
	    dv[d] = parseInt(v.value)
    }

    return dv
}

function gen_days_between_array(das,dbs) {

    let rd = []
    let current = datefns.parseISO(das)
    let db = datefns.parseISO(dbs)
    
    while (datefns.isBefore(current,db)) {

	rd.push(current.toISOString())

	current = datefns.addDays(current,1)
    }

    return rd
	   
}

function get_midnight_s(dts) {

    return get_midnight(datefns.parseISO(dts))

}
function get_midnight(dt) {

    let rdt = new Date(dt)
    rdt.setHours(0,0,0,0);

    return rdt
}


 function gen_days_between_hash(das,dbs) {

    let rh = {}
    let current = get_midnight(datefns.parseISO(das))
    let db = datefns.parseISO(dbs)
    
    while (datefns.isBefore(current,db)) {

	rh[current.toISOString()] = 0

	current = get_midnight(datefns.addDays(current,1))
    }

    return rh
}
/*
export function days_between_hash(das,dbs) {

    let rh = {}
    let current = get_midnight(datefns.parseISO(das))
    let db = datefns.parseISO(dbs)

    //get difference between two days
    while (datefns.isBefore(current,db)) {

	rh[current.toISOString()] = 0

	current = get_midnight(datefns.addDays(current,1))
    }

    return rh
}
*/

export function days_between_hash(startDate, endDate) {
    console.log(startDate,endDate)
   let days = {};
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    let dateKey = currentDate.toISOString().split('T')[0];
      days[dateKey] = 0 //get_date_string(currentDate)//currentDate.toDateString();
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return days;
}

export function days_between_array(startDate, endDate) {
    console.log(startDate,endDate)
   let days =[] ;
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    let dateKey = currentDate.toISOString().split('T')[0];
      days.push(dateKey) //get_date_string(currentDate)//currentDate.toDateString();
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return days;
}




function get_dates_in_range(da,db,dates) {

    for (d of dates) {
	
	//let db = datefns.parseISO(dbs)
	console.log(d)
    }

}

export function hoursToDaysAndTimeString(hours) {
  let wholeDays = Math.floor(hours / 24);
  let remainingHours = hours % 24;
  let wholeHours = Math.floor(remainingHours);
  let minutes = Math.round((remainingHours - wholeHours) * 60);

  // Make sure the minutes are formatted as two digits
  let minutesString = minutes.toString().padStart(2, "0");

  return `${wholeDays}d ${wholeHours}:${minutesString}`;
}


export function hoursToTimeString(hours) {
  let wholeHours = Math.floor(hours);
  let minutes = Math.round((hours - wholeHours) * 60);

  // Make sure the minutes are formatted as two digits
  let minutesString = minutes.toString().padStart(2, "0");

  return `${wholeHours}:${minutesString}`;
}


export function hours_between_dates(date1, time1, date2, time2) {
  // Combine the dates and times into Date objects
  let dateTime1 = new Date(`${date1}T${time1}:00`);
  let dateTime2 = new Date(`${date2}T${time2}:00`);

  // Get the difference between the two Date objects in milliseconds
  let millisecondsDiff = Math.abs(dateTime1 - dateTime2);

  // Convert the difference from milliseconds to hours
  return millisecondsDiff / 1000 / 60 / 60;
}

export function hours_between_dates_d(date1, date2, time2) {
  // Combine the dates and times into Date objects
  let dateTime1 = date1
  let dateTime2 = new Date(`${date2}T${time2}:00`);

  // Get the difference between the two Date objects in milliseconds
  let millisecondsDiff = Math.abs(dateTime1 - dateTime2);

  // Convert the difference from milliseconds to hours
  return millisecondsDiff / 1000 / 60 / 60;
}



export function is_current_date(isoDateString) {
  // Get the current date as an ISO string
  const currentDate = new Date();

  // Get the year, month, and day components of the ISO date string
  const [year, month, day] = isoDateString.substr(0, 10).split('-');

  // Create a new date object for the ISO date string
  const date = new Date(year, month - 1, day);

  // Compare the two dates and return true if they match
  return currentDate.getFullYear() === date.getFullYear() &&
         currentDate.getMonth() === date.getMonth() &&
         currentDate.getDate() === date.getDate();
}


export function getCurrentUtcTimeAsIsoString() {
    return new Date().toISOString();
}




// Timeseries helpers

