<template>
    <div class="bg-gray-100 p-8 rounded-lg shadow-md">
    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Cards Import</h1>
  <div>
    <input type="file" @change="handleFileUpload" accept=".csv" />
    <button class="px-4 py-2 bg-blue-500 text-white rounded" @click="uploadFile" :disabled="!selectedFile">Upload</button>
  </div>
  </div>
</template>

    <script>


import {new_card_import} from '@/services/card-helper.js';

 export default {
   data() {
     return {
       selectedFile: null,
     };
   },
   methods: {
     handleFileUpload(event) {
       this.selectedFile = event.target.files[0];
     },
     uploadFile() {
       if (!this.selectedFile) {
	 alert("Please select a file first.");
	 return;
       }

       const reader = new FileReader();
 reader.onload = (e) => {
   const fileContent = e.target.result;

   // Split the content by new line to get each row
   const rows = fileContent.split(/\r?\n/);

   // Process each row
   rows.forEach((row, index) => {
     // Splitting each row by comma for CSV values
     // Note: This is a simple split and might not handle all CSV cases like quoted commas.
       const columns = row.split(',');
//       const columns = row.split('\t');

     // Process each column or the whole row as needed
       console.log(`Row ${index}: `, columns);

       if (columns.length == 2)
	   new_card_import(columns[0],columns[1])
  });
};

reader.readAsText(this.selectedFile);

      // Reset the selected file
      this.selectedFile = null;
    },
  },
};
</script>
