<template>
  <div class="text-gray-600 body-font">
    <div class="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
      <img class="lg:w-1/6 md:w-2/3 w-full" src="logo.png" alt="non-profit logo" placeholder="Your logo here">
      <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Making a Difference, One Step at a Time</h1>
        <p class="mb-8 leading-relaxed">Your non-profit organization's mission statement or a brief description goes here. Highlight what you do, whom you help, and how your visitors can contribute or get involved.</p>
        <div class="flex w-full justify-center">
          <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Get Involved</button>
        </div>
      </div>
    </div>
    <section class="text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">What We Do</h1>
          <p class="lg:w-1/2 w-full leading-relaxed text-base">Explain more about the projects, causes, or activities your non-profit is involved in. This section can detail your methods, goals, and any success stories.</p>
        </div>
        <!-- Add more content here -->
      </div>
    </section>
    <footer class="text-gray-600 body-font">
      <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <img src="footer-logo.png" alt="Footer logo" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full">
          <span class="ml-3 text-xl">Your Non-Profit Name</span>
        </a>
        <p class="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">©2020 Your Non-Profit Name —
          <a href="https://twitter.com/your_non_profit" rel="noopener noreferrer" class="text-gray-600 ml-1" target="_blank">@your_non_profit</a>
        </p>
        <!-- Add more links or social media handles here -->
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* You can add global styles here */
</style>
