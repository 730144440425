<template>
  <div class="text-input-question">
    <label :for="'question-' + question.id" class="block mb-2 font-bold">{{ question.prompt }}</label>
    <input
      :id="'question-' + question.id"
      v-model="userAnswer"
      type="text"
      class="border rounded w-full p-2"
      @input="updateAnswer"
    />
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  data() {
      return {
//	  question: null
      userAnswer: ''
    };
  },
  methods: {
    updateAnswer() {
      // Emit the user's answer to the parent component
      this.$emit('input', this.userAnswer);
    },
  
  },
      mounted() {
	  console.log("QI:",this.question)
      }
}
</script>

<style scoped>
/* You can add any additional styles for the TextInputQuestion component here */
</style>
