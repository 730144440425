import { createStore } from 'vuex';
import { server } from '@/constants.js';
import { backend_get } from '@/services/cognito-auth';
export default createStore({
    state: {
	isLoggedIn: false,
	selectedChannel: null,
	updateTasks: null,
	featureFlags: {}
    },
    mutations: {
	logIn(state) {
	    state.isLoggedIn = true;
	},
	logOut(state) {
	    state.isLoggedIn = false;
	},
	selectChannel(state, channel) {
	    state.selectedChannel = channel;
	},
	updateTasks(state) {
	    state.updateTasks = true
	},
	setFeatureFlags(state, flags) {
	    state.featureFlags = flags;
	},
	updateFeatureFlag(state, { flag, value }) {
	    state.featureFlags[flag] = value;
	}
    },
    actions: {
	async fetchFeatureFlags({ commit }, userId) {
	    console.log("Fetch feature flags")

	    try {

		const response = await backend_get(`${server}/feature-flags`);
		console.log(response)

		if (response.data) {
		const flagsArray = response.data.flags;
		const flagsObject = {};

		flagsArray.forEach(flag => {
		    flagsObject[flag] = true;
		});
		commit('setFeatureFlags', flagsObject);
	    }
	    } catch (error) {
		console.error("Failed to load feature flags:", error);
	    }

	},

  }

    
});
