<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full bg-white p-6 rounded-lg shadow-md">
      <div>
        <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Login
        </h2>
      </div>
      <form class="mt-8" @submit.prevent="handleLogin">
        <div class="rounded-md shadow-sm">
          <div>
            <input v-model="username" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="text" placeholder="Email" />
          </div>
          <div class="-mt-px">
            <input v-model="password" @keyup.enter="handleLogin" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="password" placeholder="Password" />
          </div>
        </div>

        <div class="mt-6 flex items-center justify-between">
          <div class="flex items-center">
            <router-link class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150" to="/reset-password">
              Forgot Password?
            </router-link>
          </div>

          <div class="text-sm leading-5">
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
              Login
            </button>
          </div>
        </div>
      </form>
      <div v-if="errorMessage" class="mt-4 text-center text-red-500">
          {{ errorMessage }}
      </div>

      
    </div>
  </div>
</template>

<script>
import { login } from '@/services/cognito-auth';
  
export default {
  data() {
    return {
      username: '',
	password: '',
	errorMesage: null
    };
  },
  methods: {
    async handleLogin() {
    // Perform input validation
    if (!this.username || !this.password) {
      alert('Please enter your username and password.');
      return;
    }

    // Perform login
    try {
      // TODO: Replace this with actual login logic
      //await new Promise((resolve, reject) => setTimeout(resolve, 1000));

	login(this.username,this.password, async () => {
	    this.$store.commit('logIn');

	    await this.$store.dispatch('fetchFeatureFlags');
	    this.$router.push('/cards');}, () => {
		console.log("FAIL")
	    })
    } catch (error) {
	console.error('Failed to log in:', error);
	this.errorMessage = `Failed to log in: ${error}`

    }
  },
},
};
</script>
