<template>
  <div class="p-4">

    <div ref="pdfContainer" class="p-4">
    <!-- PDF viewer content goes here -->
    </div>

  </div>
</template>

<script>
//import * as pdfjsLib from 'pdfjs-dist';
import pdfjsLib from 'pdfjs-dist';


  import { getDocument } from 'pdfjs-dist';
  import { GlobalWorkerOptions } from 'pdfjs-dist';



// Set the path to the worker.js file (relative to your HTML file)
GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

export default {
  data() {
    return {
      pdfDoc: null,
    };
  },
  mounted() {
   
      //this.pdfDoc = pdfDoc_;
      this.renderPages();
   // });
  },
  methods: {
      renderPages() {
	  getDocument('https://arxiv.org/pdf/2304.05332.pdf').promise.then(pdfDoc => {
	      
	  for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {

	      pdfDoc.getPage(pageNum).then(page => {

		    const scale = 1.5;
		    const viewport = page.getViewport({ scale });

		    // Prepare canvas using PDF page dimensions
		    const canvas = document.createElement('canvas');
		    const context = canvas.getContext('2d');
		    canvas.height = viewport.height;
		    canvas.width = viewport.width;


		    // Append canvas to the DOM
		    this.$refs.pdfContainer.appendChild(canvas);
	    
		    // Render PDF page into canvas context
		    const renderContext = {
			canvasContext: context,
			viewport: viewport,
		    };
		    page.render(renderContext);

		    });

	    }
	 })   
    },
  },
};

</script>

<style scoped>
</style>
