<template>
  
<div class="w-full h-full overflow-auto bg-white p-4" ref="messageContainer">
  <h2 class="font-bold text-lg mb-4 cursor-pointer">Topic:{{topic}}</h2>
  <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="research" >Research</button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="tasks" >Tasks</button>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="upload" >Upload</button>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="summary" >Summary</button>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="docgen" >Doc Gen</button>
         <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="settings" >Settings</button>
  <input type="file" @change="onFileSelected">
  <div class="mb-4" v-for="(message, index) in messages" :key="index" >
    
    <h3 class="font-bold">{{ message.sender }} <span class="text-sm text-gray-500">{{ message.time }}</span></h3>
    <div class="flex justify-between items-start">
      <div v-if="editingMessageId !== message.id">
      <p v-html="formatMessage(message.text)"></p>
      </div>

	  <textarea v-else v-model="editedMessageText" class="border rounded w-full"></textarea>


	 
      <div class="relative">
	<button @click="showMessageDialog(index)" class="bg-blue-500 text-white px-4 py-2 rounded">
	  ...
	</button>
	<div v-if="showMenu == index" class="absolute right-0 mt-2 w-48 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg">
      
	  <div class="py-1">
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="editMessage(message.text,message.id)">Edit</a>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click="deleteMessage">Delete</a>
	  </div>
	</div>
      </div>
     </div>
  </div>
    <div class="mt-4">
      <!--
	  <input class="border rounded w-full py-2 px-3" type="text" v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type your message here...">
	  -->

    <textarea  class="transition-all duration-300 border rounded w-full" :style="{ height: textareaHeight + 'px' }" v-model="newMessage"  @keyup="messageHandler"  placeholder="Type your message here..."></textarea>
    

    <!--
     <textarea 
  v-else 
  v-model="newMessage" 
  @keydown.shift.enter="growTextarea" 
  class="transition-all duration-300 border rounded w-full" 
  :style="{ height: textareaHeight + 'px' }"
       ></textarea>
     -->

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { get_token,backend_get,backend_post } from '@/services/cognito-auth';
import { server } from '@/constants.js';



function extractURL(str) {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    // Find URLs in the string
    const urls = str.match(urlRegex);
    
    // If URLs are found, return the first one. Otherwise, return null.
    return urls && urls.length > 0 ? urls[0] : null;
}





export default {
  data() {
      return {
	  topic:'',
	  newMessage: '',
	  messages: [],
	  selectedFile: null,
	  showMenu: -1,
	  editingMessageId: null,
	  editedMessageText: '',
	  textareaHeight: 70,
    };
  },
    methods: {
	scrollToBottom() {
    this.$nextTick(() => {
      this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
    });
  },
	showMessageDialog(index) {
	    console.log("SM:",index)
	    /*
	    if (this.showMenu > -1)
		this.showMenu = -1
	    else
	    this.showMenu = index
	    */

	    this.showMenu = this.showMenu > -1 ? -1 : index
	},
	editMessage(text,id) {
	    // Edit message logic here
	    console.log("EDIT ",id)
	    this.editingMessageId = id;
	    this.editedMessageText = text
	    this.showMenu = -1;
	},
	deleteMessage(id) {
	    // Delete message logic here
	    console.log("Delete",id)
	    this.showMenu = -1;
	},
	growTextarea(event) {
	    console.log("GROW")
      this.textareaHeight += 20; // Increase the height by 20px
    },
	formatMessage(text) {
	    text = text.replace(/\n/g, '<br>');
	    return text.replace(/(https?:\/\/[^<\s]+)(<br>)?/g, '<a href="$1" target="_blank">$1</a>$2');

	},
	onFileSelected(event) {
	    this.selectedFile = event.target.files[0];
	},
	research() {
	    console.log("RESEARCH")
	    let project_id = this.$store.state.selectedChannel.id
	    this.$router.push({ name: 'ResearchProject', params: { projectId: project_id } });
	},
		tasks() {
	    console.log("tasks")
	    let project_id = this.$store.state.selectedChannel.id
	    this.$router.push({ name: 'Tasks', params: { projectId: project_id } });
	},
	docgen() {
	    let project_id = this.$store.state.selectedChannel.id
	    this.$router.push({ name: 'DocGen', params: { projectId: project_id } });
	},
	settings() {
	    let project_id = this.$store.state.selectedChannel.id
	    this.$router.push({ name: 'ProjectSettings', params: { projectId: project_id } });
	},
	async upload() {
	    console.log("upload")
	    let project_id = this.$store.state.selectedChannel.id
	    let upload_url = await backend_get(`${server}/upload/url/${project_id}/${this.selectedFile.name}`)
	    console.log(upload_url.data)
	    let fields = upload_url.data.fields
	    

	    
	    const formData = new FormData();

	    Object.keys(fields).forEach(key => {
		formData.append(key, fields[key]);
	    });
            formData.append('file', this.selectedFile, this.selectedFile.name);

	    const result = await axios({
		method: 'post',
		url:  upload_url.data.url,
		data: formData,
		headers: {
		    'Content-Type': 'multipart/form-data'
		}
		})

	    console.log(result)
	    /*
	    let r = await axios.put(upload_url.data.url, formData);
	    console.log(r)
	    */
	    
	},
	async summary() {
	    console.log("Summary")
	},
	messageHandler(e) {
	    if (e.code == 'Enter' && !e.shiftKey) {
//		console.log("Submit:",e)
		this.sendMessage()
		this.textareaHeight = 70
	    }
	    else if ( e.code == 'Enter' && e.shiftKey) {
		console.log("GROW")
		 this.textareaHeight += 20; // Increase the height by 20px
	    }

	},
	async sendMessage() {
	    if (this.newMessage.trim() == '')
		return
	    

	      let message = {
			  message: {text: this.newMessage },
			  project_id: this.$store.state.selectedChannel.id
	      }

	    const randomNumber = Math.floor(Math.random() * 900) + 100;


	      let response = await backend_post(`${server}/messages/add`, message)
			       console.log(response.data);
			       let id = response.data.id 



			       //todo move this up and and use a temp id 
			       

	    this.messages.push({ sender: 'You', time: 'Just now', text: this.newMessage,id:id });
	  	      

		


	      //command response
	    if (response.data.response.length)
	    this.messages.push({ sender: 'Bot', time: 'Just now', text: response.data.response });


			  
/*
	    //check if link and get summary
	    console.log("WTF:",this.newMessage)
	    let url = extractURL(this.newMessage)
	    if (url) {
		console.log("URL IN MESSAGE:",url)

		let data = {'url':url,
			    'project_id':this.$store.state.selectedChannel.id}
		let r = await backend_post(`${server}/messages/link/summary`,data)
		console.log(r)

		this.messages.push({ sender: 'Bot', time: 'Just now', text: r.data.summary });
	    }
*/
	    this.newMessage = '';
      
	},
},
computed: {
  selectedChannel() {
    return this.$store.state.selectedChannel;
  },
},
 watch: {
  selectedChannel(newChannel, oldChannel)  {
      console.log('Selected channel changed:', newChannel,oldChannel);
      this.textareaHeight = 70
      //this.messages = []
      get_token().then(token => {
	const config = {
	    headers:{
    		"Authorization":  token,

	    }
	}

	  this.topic = newChannel.name
	  
	  this.messages = []
      	axios.get(`${server}/messages/${newChannel.id}`,config)
	.then(response => {
	console.log("RESPONSE:",response.data)

	    response.data.forEach(m => {
		this.messages.push({ sender: 'Jeremy', time: m.created_date , text: m.message.text , id: m.id});
		this.scrollToBottom();
})
      })
      .catch(error => {
        console.error(error);
      })

    // Update the chat window here
  })
  }
     }
};
</script>
