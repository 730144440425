<template>
  <div id="app" class="flex h-screen">
    <side-nav></side-nav>
    <chat-window></chat-window>
  </div>
</template>

<script>
import SideNav from './SideNav.vue'
import ChatWindow from './ChatWindow.vue'

export default {
  name: 'App',
  components: {
    'side-nav': SideNav,
    'chat-window': ChatWindow
  }
}
</script>

<style>
  @import url("../assets/output.css");

</style>
