<template>
  <div class="bg-gray-100 p-8 rounded-lg shadow-md">
    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Stats</h1>
       	<h1  class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Cards Total: {{ cardsTotal }}</h1>
   	<h1  class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Cards Tomorrow: {{ cardsTom }}</h1>
  </div>
</template>


<script>
import axios from 'axios';
import { get_token,backend_get,backend_upload } from '@/services/cognito-auth';
import * as zeit from '@/services/zeit.js';
import { server } from '@/constants.js';
  
export default {
  data() {
    return {
    	cardsTom: 0,
	cardsTotal: 0
    };
  },
  methods: {
    

      async get_stats() {

	  let d = new Date()
	  d = zeit.plus_days(d,1)
	      let due_date = zeit.get_date_string(d)

	      let url = `${server}/cards/due/count?date=${due_date}`
	      let response = await backend_get(url)

	  this.cardsTom = response.data

	   url = `${server}/cards/count?active=true`
	   response = await backend_get(url)
	  console.log(response)
	  this.cardsTotal = response.data.cards.count

      }

  },
          async mounted() {

	      console.log("Load Stats")
	      this.get_stats()
	  }
}

</script>
