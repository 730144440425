<template>
  <div class="container mx-auto p-6">

    <!-- Search Bar -->
    <div class="mb-4 flex">
      <input type="text" v-model="searchQuery" placeholder="Search..." class="p-2 border border-gray-300 rounded-l w-full">
      <button @click="handleSearch" class="p-2 bg-blue-500 text-white hover:bg-blue-600 rounded-r">Search</button>
    </div>
<div>
    <input type="checkbox" id="hideCB" v-model="hideCB" @change="handleCB" />
    <label for="myCheckbox">Show Hidden</label>
    </div>
  <table class="min-w-full bg-white border border-gray-300">
    <thead>
        <tr>
            <th class="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider" v-for="header in headers" :key="header">{{ header }}</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="card in cards" :key="card.id">
            <td class="py-2 px-4 border-b border-gray-300">
                <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" :to="`/cards/edit/${card.id}`">{{ card.front }}</router-link>
            </td>
            <td class="py-2 px-4 border-b border-gray-300">{{ card.back }}</td>
            <td class="py-2 px-4 border-b border-gray-300">{{ card.dueDate }}</td>
            <td class="py-2 px-4 border-b border-gray-300">{{ card.hide }}</td>
            <!-- Add more columns as needed -->
            <td class="py-2 px-4 border-b border-gray-300">
              <div class="flex space-x-2"> <!-- Flex container to align buttons horizontally with spacing -->
		 <button @click="hideCard(card.id)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                        {{ card.hide == true ? 'Show' : 'Hide' }}
                    </button>
                    <button @click="deleteCard(card.id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                        Delete
                    </button>
                   
                </div>
            </td>
        </tr>
    </tbody>
   

</table>

 <div class="mt-4 flex justify-center">
    <button @click="prevPage()" :disabled="currentPage <= 0" class="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-l">Previous</button>
  <span class="px-4 py-2 bg-gray-200">{{ currentPage + 1 }} / {{ totalPages }}</span>
    <button @click="nextPage()"  :disabled="currentPage >= totalPages" class="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-r">Next</button>
    </div>

  </div>
</template>

<script>
import { server } from '@/constants.js';
import { backend_get,backend_post,backend_delete } from '@/services/cognito-auth';
import * as zeit from '@/services/zeit.js';

export function toPlaintext(delta) {
    console.log("DELTA:",delta)
    return delta.reduce(function (text, op) {
	if (!op.insert) return ''//throw new TypeError('only `insert` operations can be transformed!');
	if (typeof op.insert !== 'string') return text + ' ';
	return text + op.insert;
    }, '');
};

export default {
 
    data() {
	return {
	    headers: ['Front', 'Back', 'Due Date', 'Hidden','Actions'],
	    cards: [],
	    currentPage: 0,
	    itemsPerPage: 10,
	    totalPages: 100,
	    activeLinkClass: "font-bold border-l-4 border-blue-600 pl-2",
	    hideCB: false
	};
    },
   
    methods: {
	handleCB () {
	    console.log("CB")

	    
	    },
	async load_cards() {

	console.log("load cards")

	let offset = this.currentPage * this.itemsPerPage
	    let cards = await backend_get(`${server}/cards?offset=${offset}`)
	    console.log(cards)
	this.cards = []
	cards.data.cards.forEach((card) => {
	    //console.log(card)
	    let jsd = card.jsondata

	    //this.cards.push({ front: toPlaintext(jsd.from), back: toPlaintext(jsd.back), dueDate: '2022-07-20' })
	    this.cards.push({ id: card.id,
			      front: toPlaintext(jsd.front.ops),
			      back: toPlaintext(jsd.back.ops),
			      dueDate: zeit.get_date_string(zeit.toLocalTime(card.due_date)),
			    hide: card.hide})
	})



	},
	async deleteCard(id) {
	    console.log("delete:",id)

	    if (confirm("Are you sure you want to delete this card?")) {
		console.log("CONFIRM DELETE")

		let r = await backend_delete(`${server}/cards/delete/${id}`)
		console.log(r)
		this.load_cards()
	    }
	},
	async hideCard(id) {
	    console.log("hide:",id)

	    let r = await backend_post(`${server}/cards/toggleHide/${id}`,{})
	    console.log(r)
	    this.load_cards()

	    
	},
	prevPage() {

	    console.log("prev")
	    this.currentPage -= 1
	    this.load_cards()
	},
	nextPage() {
	    console.log("NEXT")
	    this.currentPage += 1
	    this.load_cards()
	    
	},
	async handleSearch() {
      // Implement your search logic here
	    console.log("Searching for:", this.searchQuery);
	let offset = this.currentPage * this.itemsPerPage
	    let cards = await backend_get(`${server}/cards/search?term=${this.searchQuery}&offset=${offset}`)
	    console.log(cards)
	this.cards = []
	cards.data.cards.forEach((card) => {
	    //console.log(card)
	    let jsd = card.jsondata

	    //this.cards.push({ front: toPlaintext(jsd.from), back: toPlaintext(jsd.back), dueDate: '2022-07-20' })
	    this.cards.push({ id: card.id,
			      front: toPlaintext(jsd.front.ops),
			      back: toPlaintext(jsd.back.ops),
			      dueDate: zeit.get_date_string(zeit.toLocalTime(card.due_date)),
			    hide: card.hide})
	})

	    
	}
    },
    async mounted() {
	console.log("Mounted")
 
	let r =  await backend_get(`${server}/cards/count`)
	console.log(r.data.cards.count)
	 this.totalPages = Math.ceil(r.data.cards.count / this.itemsPerPage);

	this.load_cards()

    },

};
</script>

<style scoped>
/* You can add any additional styles specific to this component here */
</style>
