<template>
  <div class="bg-gray-100 p-8 rounded-lg shadow-md">
    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Cards Help</h1>
    <p class="mb-4 text-gray-700">
      This is a flash card app that uses Space Repetition to help you learn and retain information. Space repetition is a system based on neuroscience on when best to show you a piece of information.
    </p>
    <p class="mb-4 text-gray-700">
      The human brain is, to put it charitably, efficient. It determines what's important based on certain cues. While cramming might help you pass a test, the information won't stick around for long.
    </p>
    <p class="mb-4 text-gray-700">
      Space repetition presents information just as you're about to forget it, signaling to your brain that it's important. You give the app feedback on easy or difficult it was to remember a card,
      which in turn changes the length of time for review for that card.
    </p>
    <p class="mb-4 text-gray-700">
      Currently, there are two additional features in this app:
    </p>
    <ul class="list-disc pl-5 mb-4 text-gray-700">
      <li>Ability to add a drawing to a card</li>
      <li>For language learners: automatic audio generation</li>
    </ul>
    <p class="mb-4 text-gray-700">
      Adding images can aid recall. While many apps allow image addition, it's cumbersome to find and upload an image for every card. Moreover, abstract ideas like "complacency" or "essential" aren't easily represented by images. By allowing to draw an image you can create anything as a custom hook for recall.
    </p>
    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">New Card </h1>
    <p>
      Create a new card. You can then draw an image to be displayed on the front of the card.
      </p>
    <ul class="list-disc pl-5 text-gray-700">
      <li>The first line of text will be converted to speech to be played</li>
	</ul>
	
    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Review Card Controls</h1>
    <p>
      All buttons are mapped to the keyboard.
      <ul class="list-disc pl-5 text-gray-700">
	<li>Show - Shows back of card</li>
	<li>Easy - Card was easy to recall</li>
	<li>Good - Card was recalled but took a slight effort</li>
	<li>Ok - Card took a bit of effort</li>
	<li>Hard - Card was very difficult or couldn't recall at all</li>
	<li>Play - Will play the audio text to speech of the first line of the back of the card</li>
	<li>Hide - Take this card out of rotation. Can be undone in the View All</li>
	</ul>
      </p>
    <p class="font-semibold mb-2 text-gray-800">Recommendations:</p>
    <ul class="list-disc pl-5 text-gray-700">
      <li>Use the cloze method</li>
    </ul>
  </div>
</template>


<script>

  
export default {
  data() {
    return {
    
    };
  },
  methods: {
    


}
}

</script>
