<template>
  <div class="container mx-auto p-6 text-center">
    <h1 class="text-2xl font-bold mb-4">Signed Out</h1>
    <p class="mb-4">You have successfully signed out.</p>
    <router-link to="/login" class="text-blue-600 hover:underline">Go to Login</router-link>
  </div>
</template>

<script>
  import { logout } from '@/services/cognito-auth';

  
export default {
  name: 'SignOut',
  mounted() {
    this.signOut();
  },
    methods: {
	signOut() {
      // Stub for your sign out logic
	    console.log("User signed out");
	    logout()
	     this.$router.push(`/login`)
    }
  }
}
</script>

<style scoped>
/* Add any additional styles here */
</style>
