<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full bg-white p-6 rounded-lg shadow-md">
      <div>
        <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Set New Password
        </h2>
      </div>
      <form class="mt-8" @submit.prevent="handleLogin">
        <div class="rounded-md shadow-sm">

<div>
            <input v-model="username" class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="email" placeholder="Email address" />
          </div>
          <div class="-mt-px">
            <input v-model="password" @keyup.enter="handleLogin" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="password" placeholder="New Password" />
          </div>


	<div class="-mt-px">
            <input v-model="vcode" @keyup.enter="handleLogin" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="text" placeholder="Verification Code" />
          </div>
        </div>

	

        <div class="mt-6 flex items-center justify-between">
          <div class="flex items-center">
           
          </div>

          <div class="text-sm leading-5">
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
              Register
            </button>
          </div>

	  

	  
        </div>
    </form>
          <div v-if="errorMessage" class="mt-4 text-center text-red-500">
          {{ errorMessage }}
</div>
    
    </div>
  </div>
</template>


<script>
import { update_password } from '@/services/cognito-auth';
  
export default {
        props: {
	email: {
	    type: String, // or Number, depending on your use case
	    required: true
	},
	    code: {
		type: Number,
		required: true
	    }
    },
  data() {
    return {
	username: '',
	password:'',
	vcode: '',
	errorMesage: null
    };
  },
  methods: {
    async handleLogin() {
    // Perform input validation
	if (!this.username || !this.password || !this.vcode) {
      alert('Please enter your username and password.');
      return;
    }

	console.log("update password")
	update_password(this.username,this.vcode, this.password)
	
  },
  },
    mounted() {
	console.log("Mounted")
	this.username = this.email
	this.vcode = this.code
    }
};
</script>
