<template>
  <div class="h-screen flex flex-col">
    <!-- Chat History -->
    <div class="flex-1 overflow-y-auto p-4">
      <div v-for="(message, index) in messages" :key="index" class="mb-4">
        <!-- Received Message -->
        <div v-if="message.type === 'received'" class="text-left">
          <div class="text-sm text-gray-600 mb-1">{{ message.name }} · {{ message.timestamp }}</div>
          <div class="inline-block bg-gray-200 rounded px-4 py-2 w-full" v-html="message.content">

          </div>
        </div>
        <!-- Sent Message -->
        <div v-else class="text-left">
          <div class="text-sm text-gray-600 mb-1">{{ message.name }} · {{ message.timestamp }}</div>
          <div class="inline-block bg-blue-200 rounded px-4 py-2 w-full" v-html="message.content">

          </div>
        </div>
      </div>
    </div>

    <!-- Message Input -->
    <!--
    <div class="border-t p-4 bg-white">
      <textarea
        v-model="newMessage"
        @keyup.enter="sendMessage"
        class="w-full rounded p-2 border"
        placeholder="Type your message..."
      ></textarea>
    </div>
    -->

    
  <div class="p-4">
    <quill-editor ref="editor" v-model:content="content" content-type="html" theme="snow" class="quill-container"/>
    <button @click="sendMessage" class="mt-4 px-4 py-2 bg-blue-500 text-white rounded">Send</button>
  </div>
  </div>
</template>


    <script>
    import { backend_get,backend_post } from '@/services/cognito-auth';
import { server } from '@/constants.js';

import { ref } from 'vue';

import { QuillEditor, Delta } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import '@vueup/vue-quill/dist/vue-quill.bubble.css';


function addTailwindClassesToHtml(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Add classes to <p> tags
  doc.querySelectorAll('p').forEach(p => {
    p.classList.add('mb-4');  // Example: Add margin-bottom
  });

    //list-decimal
    
    // Add classes to <li> tags
    /*
  doc.querySelectorAll('li').forEach(li => {
    li.classList.add('list-decimal', 'ml-5');  // Example: Add list style and margin-left
    });
    */

      doc.querySelectorAll('ol').forEach(ol => {
    ol.classList.add('list-decimal');  // Example: Set list style position inside
      });
    
  // Add classes to <ul> tags
  doc.querySelectorAll('ul').forEach(ul => {
    ul.classList.add('list-disc');  // Example: Set list style position inside
  });

  // Add classes to <h1> tags
  doc.querySelectorAll('h1').forEach(h1 => {
    h1.classList.add('text-4xl', 'font-bold', 'mb-4');  // Example: Set font size, font weight, and margin-bottom
  });

  // Add classes to <h2> tags
  doc.querySelectorAll('h2').forEach(h2 => {
    h2.classList.add('text-3xl', 'font-semibold', 'mb-3');  // Example: Set font size, font weight, and margin-bottom
  });

  // Convert the modified document back to a string
  return doc.body.innerHTML;
}

// Usage



export default {
     components: {
    QuillEditor
  },
  data() {
    return {
	newMessage: "",

      messages: [

      ],
    };
  },
      setup() {

	  //const contentDelta = ref<Delta>
	  //const content = ref<Delta | null>(null)
	  //	  const contentHTML = ref('')
	  let content = ref('')
	  const update = (html) => {
	      console.log("UPDATE")
	      console.log(content)
	      content.setHTML("")

	  }
	  return { content,update }
	
      },
  methods: {
      async sendMessage() {
	 
	if (! this.content)
	    return

	  console.log("Send message:",this.content)
	  
	const targetId = Math.floor(Math.random() * 900) + 100;
	 let message = {
	     message: {text: this.content },
	      project_id: 0
	 }
	  const styledHtml = addTailwindClassesToHtml(this.content);
	    console.log(styledHtml);

        this.messages.push({ type: "sent", content: styledHtml, id: targetId });
          //this.content = "";

	  //this.update("")

	  console.log(this.$refs.editor)
	  this.$refs.editor.setHTML("")
	  console.log(this.content)

	
/*
	//find random num and update with id
	let response = await backend_post(`${server}/messages/new`, message)
	  console.log(response.data);
	  let newId = response.data.id 
			       




	  const targetObject = this.messages.find(obj => obj.id === targetId);
	  if (targetObject) {
	      targetObject.id = newId;
	  }
*/
	  
	
    },
  },
    async mounted() {
	console.log("MOUNTED")

	let messages = await backend_get(`${server}/messages/0`)
	console.log(messages)

	messages.data.forEach((m) => {
	    console.log(m)


	    const styledHtml = addTailwindClassesToHtml( m.message.text);
	    console.log(styledHtml);

	    this.messages.push({ name: 'Jeremy', timestamp: m.created_date , content: styledHtml , id: m.id});
	})
    }
};
</script>

<style scoped>
    /* You can add any additional styles here if needed */

.quill-container .ql-container {
  max-height: 150px; /* or whatever height you prefer */
  overflow-y: auto;
}
</style>
