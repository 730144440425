<template>
   <div class="w-64 bg-white p-4">
    <!-- General Links -->
    <div>
      <h2 class="font-bold text-lg mb-4">General</h2>
      <ul>
        <li class="py-1"> <router-link to="/cards">Cards</router-link></li>

        <!-- Add more general links here -->
      </ul>
    </div>

    <!-- Channels -->
 <div class="mt-6">
    <h2 class="font-bold text-lg mb-4 cursor-pointer" @click="channelsOpen = !channelsOpen">
      Projects
       <router-link to="/project/new">+</router-link>

    </h2>
    <ul v-show="channelsOpen">
<li v-for="item in items" :key="item" @click="selectChannel(item)">
  {{ item.name }}
</li>
    </ul>
</div>


    <!-- Direct Messages 
    <div class="mt-6">
      <h2 class="font-bold text-lg mb-4 cursor-pointer" @click="messagesOpen = !messagesOpen">
        Direct Messages
      </h2>
      <ul v-show="messagesOpen">
        <li class="py-1">User 1</li>
        <li class="py-1">User 2</li>

      </ul>
    </div>
    -->
  </div>
</template>

<script>
import axios from 'axios';
import { get_token } from '@/services/cognito-auth';
import { server } from '@/constants.js';




export default {
  data() {
    return {
	channelsOpen: true,
	messagesOpen: true,
      items:  []

    };
  },
    methods: {
	addChannel() {
	    let newChannelName = window.prompt('Enter the name of the new channel:');
	},
    selectChannel(channel) {
      this.selectedChannel = channel;
	console.log('Selected channel:', this.selectedChannel);
	this.$store.commit('selectChannel', channel);
    },
    // ...
  },    
    async mounted() {

	
	const config = {
	    headers:{
    		"Authorization": await get_token(),

	    }
	};
	/*
	  headers: {
		"Authorization": token
	    },  */
	
	axios.get(`${server}/channels`,config)
	.then(response => {
	console.log("RESPONSE:",response.data, [{ name: 'F111oo' }, { name: 'B111ar' }])
	this.items = response.data;

      })
      .catch(error => {
        console.error(error);
      })
  
  }


}
</script>
