<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full bg-white p-6 rounded-lg shadow-md">
      <div>
        <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Reset Password
        </h2>
        <p class="mt-2 text-center text-sm leading-5 text-gray-600">
          Enter your email address and we'll send you a link to reset your password.
        </p>
      </div>
      <form class="mt-8" @submit.prevent="handleReset">
        <div class="rounded-md shadow-sm">
          <div>
            <input v-model="email" class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="email" placeholder="Email address" />
          </div>
        </div>

        <div class="mt-6">
          <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
            Send Reset Link
          </button>
        </div>
      </form>
           <div v-if="errorMessage" class="mt-4 text-center text-red-500">
          {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
  import { forgot_password_send } from '@/services/cognito-auth';
  
export default {
    
    
  data() {
    return {
	email: '',
	errorMessage: ''
    };
  },
  methods: {
    handleReset() {
      // Handle the reset password logic here.
	// For example, send the email to the backend to initiate the password reset process.
	console.log("Sending reset link to:- ", this.email); 
	forgot_password_send(this.email)
	this.errorMessage = 'Check your email for reset code'
      
    }
  }
}
</script>
