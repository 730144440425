<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full bg-white p-6 rounded-lg shadow-md">
      <div>
        <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Register
        </h2>
      </div>
      <form class="mt-8" @submit.prevent="handleLogin">
        <div class="rounded-md shadow-sm">
          <div>
            <input v-model="username" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="text" placeholder="Email" />
          </div>
	  
          <div class="-mt-px">
            <input v-model="password" @keyup.enter="handleLogin" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="password" placeholder="Password" />
          </div>


	<div class="-mt-px">
            <input v-model="code" @keyup.enter="handleLogin" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" type="text" placeholder="Invite Code" />
          </div>
        </div>

	

        <div class="mt-6 flex items-center justify-between">
          <div class="flex items-center">
           
          </div>

          <div class="text-sm leading-5">
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
              Register
            </button>
          </div>

	  

	  
        </div>
    </form>
          <div v-if="errorMessage" class="mt-4 text-center text-red-500">
          {{ errorMessage }}
</div>
    
    </div>
  </div>
</template>


<script>
import { register_code } from '@/services/cognito-auth';
  
export default {
  data() {
    return {
      username: '',
	password: '',
	code: '',
	errorMessage: ''
    };
  },
  methods: {
    async handleLogin() {
    // Perform input validation
    if (!this.username || !this.password || !this.code) {
      alert('Please enter your username/password and invite code');
      return;
    }

	console.log("REGISTER")

	register_code(this.username,this.password,this.code, (res,err) => {
	    console.log(res,err)

	    if (!err) {
		//this.$router.push('/login')
		this.errorMessage = `Verificiation email sent, please check email.`
		return
	    }
	    else
		this.errorMessage = err
	    //update error message
	})

    }
}
}
</script>
