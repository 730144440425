<template>
  <div class="p-4 border-r">
          <ul>
        <li class="py-1"> <router-link to="/chat">Chat</router-link></li>

        <!-- Add more general links here -->
      </ul>


    <!-- Chat window content goes here -->
  </div>
</template>

<script>
export default {
  // Component logic goes here
}
</script>

<style scoped>
</style>
