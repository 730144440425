<template>
  <div class="container mx-auto p-6">

    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">{{tsName}} </h1>
    
    <!-- Search Bar 
    <div class="mb-4 flex">
      <input type="text" v-model="searchQuery" placeholder="Search..." class="p-2 border border-gray-300 rounded-l w-full">
      <button @click="handleSearch" class="p-2 bg-blue-500 text-white hover:bg-blue-600 rounded-r">Search</button>
    </div>

-->

<button @click="exportCSV" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
      Export To CSV
</button>


 <input
        type="text"
        id="keyName"
        v-model="keyName"
        placeholder="Enter name"
        class="mt-1 p-2 w-full border rounded-md"
      />

    <input
        type="text"
        id="keyValue"
        v-model="keyValue"
        placeholder="Enter value"
        class="mt-1 p-2 w-full border rounded-md"
    />
<VueDatePicker v-model="dateTime"></VueDatePicker>

    <button 
      @click="saveRecord" 
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Save
    </button>
<!--
 <Bar
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
    -->
<!--
<Line :data="chartData" :options="chartOptions" />
    -->

    
    <div>
<h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Stats</h1>

<select v-model="selectedOption" class="border-gray-300 border rounded-md p-2">
      <option value="time">Time</option>
      <option value="count">Count</option>
    </select>

<button 
      @click="showStats" 
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Show Stats
    </button>

   <!-- HTML snippet for 'time' -->
    <div v-if="selectedOption === 'time'">
    <h1 class="font-bold text-1xl mb-6 text-blue-600 border-b pb-2">Total Time: {{ totalTime }}</h1>
     <div v-html="htmlTable"></div>
    </div>

<div v-if="selectedOption === 'count'">

     <div v-html="htmlTable"></div>
    </div>

</div>


  <table class="min-w-full bg-white border border-gray-300">
    <thead>
        <tr>
            <th class="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider" v-for="header in headers" :key="header">{{ header }}</th>
        </tr>
    </thead>
    <tbody>
      <tr v-for="(ts, index) in tsDatapoints" :key="index">

      <td class="py-2 px-4 border-b border-gray-300">{{ ts[1] }}</td>
      <!--
	  <td class="py-2 px-4 border-b border-gray-300">{{ ts[2] }}</td>
	  
	  -->

<!--	  
      <td class="py-2 px-4 border-b border-gray-300" @click="editIndex = index" v-if="editIndex == -1">
      <span>{{ ts[2] }}</span>
      </td>
      
    <td class="py-2 px-4 border-b border-gray-300" v-else>
      <input type="text" v-model="editableValue" @blur="saveEdit(index)" @keyup.enter="saveEdit(index)">
    </td>
    -->

    <td class="py-2 px-4 border-b border-gray-300" @click="updateIndex(index)" v-if="!indexMatch(index)">
    <span>{{ ts[2] }}</span>
  </td>
  <td class="py-2 px-4 border-b border-gray-300" v-else>
    <input type="text" v-model="editableValue" @blur="saveEdit()" @keyup.enter="saveEdit(index)">
  </td>
    

    
     <td class="py-2 px-4 border-b border-gray-300">{{ ts[0] }}</td>
           


    <!--
 <td class="py-2 px-4 border-b border-gray-300">
                <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" :to="`/quiz/edit/${quiz.id}`">{{ quiz[1] }}</router-link>
            </td>
            <td class="py-2 px-4 border-b border-gray-300">
              <div class="flex space-x-2"> 
		 <button @click="takeQuiz(quiz.id)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                       Take
                    </button>
                    
                   
                </div>
    </td>
    -->
        </tr>
    </tbody>
   

</table>
   <!--

 <div class="mt-4 flex justify-center">
    <button @click="prevPage()" :disabled="currentPage <= 0" class="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-l">Previous</button>
  <span class="px-4 py-2 bg-gray-200">{{ currentPage + 1 }} / {{ totalPages }}</span>
    <button @click="nextPage()"  :disabled="currentPage >= totalPages" class="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-r">Next</button>
    </div>
-->
</div>
    
</template>

<script>
import { server } from '@/constants.js';
import { backend_get,backend_post,backend_delete } from '@/services/cognito-auth';
import * as zeit from '@/services/zeit.js';


import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { Bar } from 'vue-chartjs'
import { Line } from 'vue-chartjs'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement,LineElement} from 'chart.js'

import * as dfd from "danfojs"

//ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

//https://vue-chartjs.org/examples/

function groupDataByActivity(data) {
    const groupedData = {};

    data.forEach(([date, activity, minutes]) => {
        if (!groupedData[activity]) {
            groupedData[activity] = { key: activity, dataPoints: [] };
        }
        groupedData[activity].dataPoints.push({ date, minutes: parseInt(minutes, 10) });
    });

    return Object.values(groupedData);
}

function arrayToCSV(data) {
    const csvRows = data.map(row =>
	{
	    row[2] = row[2].replace(',',' ')
	    return row.join(",")

	})
  return csvRows.join("\n");
}

function dataframeToHTML(df) {
  let html = '<table class="table-auto w-full"><thead><tr>';
  
  // Add column headers
  df.columns.forEach(col => {
    html += `<th>${col}</th>`;
  });
  html += '</tr></thead><tbody>';

  // Add rows
  df.values.forEach(row => {
    html += '<tr>';
    row.forEach(val => {
      html += `<td>${val}</td>`;
    });
    html += '</tr>';
  });

  html += '</tbody></table>';
  return html;
}


export default {
 

    props: {
	tsId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },
    components: {
	VueDatePicker,
	Line,
	Bar
    },

    computed: {
      chartData() { return {
	  labels: this.labels,
	  datasets: this.datasets	
      }},
	chartOptions() { return {responsive: true,
		       maintainAspectRatio: false
		     }
		       }},
    data() {
	return {
	    tsName: '',
	    tsDatapoints: [],
	    keyName: '',
	    keyValue: '',
	    headers: ['Name', 'Value','Date'],
	    quizzes: [],
	    currentPage: 0,
	    itemsPerPage: 10,
	    totalPages: 100,
	    activeLinkClass: "font-bold border-l-4 border-blue-600 pl-2",
	    dateTime: new Date(),
	    selectedOption: 'time', // Default selection
	    labels: [],
	    totalTime: 0,
	     htmlTable: '',
	    datasets: [
		    
	    ],
	    editIndex: -1, // -1 means no cell is being edited
	    editableValue: '',
	     editMode: false,
	    /*
	    chartData: {
        labels: [ 'January', 'February', 'March' ],
        datasets: [ { data: [40, 20, 12] } ]
      },
      chartOptions: {
        responsive: true
      }
    */

	};
    },
   
    methods: {
	indexMatch(index) {
	    //console.log(this.editIndex,index,this.editIndex == index)
//	    return true
	    return this.editIndex == index
	    },
	updateIndex(index) {
	    console.log(this.editIndex)
	    this.editIndex = index
	    this.editableValue = this.tsDatapoints[index][2]; 
	    },

	saveEdit(index) {
	    if (!index)
		return
	    this.editMode = false;
	    this.editIndex = -1
	    console.log("Edit: ", this.editableValue,index)
	    console.log(this.tsDatapoints[index])//[2] = this.editableValue
	    this.tsDatapoints[index][2] = this.editableValue
	    
      // Update the value in your data. For example:
      //this.ts[2] = this.editableValue;
      // Add any save logic here, e.g., emit an event or make an API call
    },
	editIndex(newIndex) {
	    if (newIndex !== -1) {
        // Initialize editableValue with the current value when entering edit mode
        this.editableValue = this.tsDatapoints[newIndex][2];
      }},
	showStats() {
	    console.log("SHOW STATS:",this.selectedOption)
	    let cols = ["DT", "Key", "Value","Year-Month"]

	    let df = new dfd.DataFrame(this.tsDatapoints,{columns: cols})

	    df = df.asType("Value", "int32")
	    df.print()
	    if (this.selectedOption == 'time') {
	
		//df['DT2'] = df['DT'].map((val) => new Date(val));
            


		let df_new = df.asType("Value", "int32")
		let ds = df_new['Value'].sum()
		this.totalTime = ds / 60

		let grouped = df.groupby(['Year-Month']);
		let aggregated = grouped.col(['Value']).sum();

		aggregated.print()

		this.htmlTable = dataframeToHTML(aggregated)
//		console.log(ds)

//		let dfn = new dfd.DataFrame(this.tsDatapoints,{columns: cols})

		
//		let s = console.log(dfd.toDateTime(df['DT']))
//		let s =  df["DT"]//.dt
//		console.log(s)
		//df['dates'] = df['DT'].toDateTime()
//		df['DT'] = df['DT'].map((val) => dfd.toDateTime(val))//new Date(val));
		//console.log(df_new)
		//let df_new2 = df.add(s, { axis: 0 })

		
		//df.addColumn("DT2", s, { inplace: true });
		//let date_group = df.groupby(["DT"]);

		//console.log(date_group)

//		let grouped_df = df.groupby(df['DT'].dt);
//		console.log(grouped_df)
		//dfn.set_index({ key: "DT", inplace: true })
		//dfn.print()
	    }
	    else {
		console.log("COUNT STATS")

		let grouped = df.groupby(['Key','Value','Year-Month']);
		let aggregated = grouped.col(['Value']).count();
		aggregated.sortValues("Year-Month", { inplace: true })

		aggregated.print()

//		this.htmlTable = dataframeToHTML(aggregated)

		grouped = df.groupby(['Key','Year-Month']);
		aggregated = grouped.col(['Value']).count()//sum();
		aggregated.sortValues("Year-Month", { inplace: true })

		aggregated.print()

		this.htmlTable = dataframeToHTML(aggregated)

	    }

//	    let grp = df.groupby()
//	    grp.agg({C:"mean",D:"sum"}).print()
	},
	takeQuiz(id) {
	    console.log("TAKE")
	    //this.$router.push(`/quiz/take/${id}`)
	},
	async exportCSV() {
	    console.log("EXPORT")

	    
	    let r =  await backend_get(`${server}/ts/get/${this.tsId}`)
	    console.log(r)

	    let fn = `${r.data.name}.csv`
	    let csvdata = "Date,Topic,Value\n" + arrayToCSV(r.data.data_points.data_points)
	    console.log(csvdata)
		
	    
	    const csvBlob = new Blob([csvdata], { type: "text/csv;charset=utf-8" });

	      const link = document.createElement("a");
	    link.href = URL.createObjectURL(csvBlob);
	    link.download = fn;
	    link.style.display = "none"; // Hide the link
	    document.body.appendChild(link); // Append to the document body
	    link.click(); // Trigger the download
	    link.remove(); // Remove the link
	    
	},
	async saveRecord() {
	    let ct = zeit.getCurrentUtcTimeAsIsoString()

	    console.log(this.dateTime.toISOString())
	    console.log(`SAVE RECORD ${this.keyName} ${this.keyValue} ${ct}`)
	    ct = this.dateTime.toISOString()
	    let ts = {ts: ct, name:  this.keyName, value: this.keyValue}

	    console.log(ts)
	    let response = await backend_post(`${server}/ts/addrecord/${this.tsId}`, ts)
	    console.log(response.data);	    

	    this.load_data_points()

	    this.keyName = ""
	    this.keyValue = ""

	    
	},
	async load_data_points() {
	    	 let r =  await backend_get(`${server}/ts/get/${this.tsId}`)
	 console.log(r)
	 this.tsName = r.data.name

	    //this.tsDatapoints = r.data.data_points.data_points

	    this.tsDatapoints = r.data.data_points.data_points.map(innerArray => {
		//innerArray[0] = innerArray[0].slice(0,10)
		//innerArray[0] = new Date(innerArray[0])
		//console.log(innerArray[0])
		innerArray[0] = new Date(innerArray[0])

		let date = innerArray[0]
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // months from 1-12

		const yearMonthString = `${year}-${month}`;
		innerArray.push(yearMonthString)
		console.log(yearMonthString); // 2024-2
		return innerArray
						     
	    })
	    // zeit.get_date_string
	    console.log(this.tsDatapoints)

	    //this.datasets[0].label = "Test"
	    //	    console.log(this.datasets[0])

	    /*
	    let tl =  r.data.data_points.data_points.map(innerArray => {
		return innerArray[0].slice(0,10)
	    })

	    let td =  r.data.data_points.data_points.map(innerArray => {
		return innerArray[2]
	    })
	    console.log(td)
	    this.labels = tl
	    this.datasets = [{
			label: 'Test',
			backgroundColor: '#f87979',
			data: td
			}]
			*/

	}
	
	},
    
     async mounted() {
	console.log("Mounted")
	 this.load_data_points()
	 /*
	 let r =  await backend_get(`${server}/ts/get/${this.tsId}`)
	 console.log(r)
	 this.tsName = r.data.name

	 this.tsDatapoints = r.data.data_points.data_points
	 console.log(this.tsDatapoints)
	 */

	 /*
	 r.data.quizzes.forEach((quiz) => {
		  console.log("Quiz:",quiz)

		  this.quizzes.push({ id: quiz.id, name:quiz.name})
})
*/
//	console.log(r.data.quizzes.count)
//	 this.totalPages = Math.ceil(r.data.quizzes.count / this.itemsPerPage);


    },

}

</script>

<style scoped>
/* You can add any additional styles specific to this component here */
</style>

