<template>
  <div class="w-64 bg-white p-6">
    <!-- General Links -->
    <div>
      <h2 class="font-semibold text-xl mb-4 border-b pb-2">Quiz</h2>

      
         <ul>
	        <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/cards" exact>Cards</router-link>
        </li>

		  <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/assist" exact>Assist</router-link>
        </li>

	 </ul>
	 <h2 class="font-semibold text-xl mb-4 border-b pb-2"></h2>
      <ul>
	<li>
       
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/quiz/list" exact>List</router-link>
        </li>

		<li>
       
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/quiz/new" exact>New</router-link>
		</li>

			<li>
       
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/quiz/import" exact>Import</router-link>
        </li>	

        <!-- Add more general links here -->
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { get_token } from '@/services/cognito-auth';
import { mapState } from 'vuex';

export default {
  data() {
    return {

	activeLinkClass: "font-bold border-l-4 border-blue-600 pl-2"
    };
  },
    methods: {
	
   
	
  },    
    async mounted() {

	console.log("Mounted")
	//console.log("featureFlags:",this.featureFlags)
	//console.log("featureFlags:",this.isLoggedIn)

	console.log("S1:",this.$store.state.isLoggedIn)
	console.log("S2:",this.$store.state.featureFlags)
	
  
    },
    computed: {
	featureFlags() {
	    return this.$store.state.featureFlags
	//...mapState(['featureFlags'])
	    //...mapState(['isLoggedIn'])
	    }
  }


}
</script>
