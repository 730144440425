<template>
  <div class="quiz-builder">
    <h1>Import a Quiz</h1>

     <button @click="save" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Save
     </button>
     
   

 <div class="p-4">
    <textarea v-model="text" class="w-full p-2 border rounded" rows="5" placeholder="Paste or type your text here"></textarea>
  </div>


  </div>
</template>

<script>
import { get_token,backend_post, backend_upload } from '@/services/cognito-auth';
import { server } from '@/constants.js';

export default {
  data() {
    return {
      quizName: '',
	questions: [],
	inputQ: '',
inputA: '',
text: ''
    };
  },
  methods: {
    addQuestion() {
	this.questions.push({ question: this.inputQ, answer: this.inputA , type:'input'});
	this.inputA = ""
	this.inputQ = ""
},
      async save() {
	  console.log("SAVE QUIZ")

	  let quiz = {name: this.quizName,
		      questions: this.questions}

	  console.log(quiz)

	  let r = await backend_post(`${server}/quiz/new`,quiz)
	  console.log(r)

      }
  }
};
</script>

<style scoped>
/* You can add styles specific to this component here */
</style>
