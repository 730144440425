<template>
  <!--
  <div>
    <h1>Research Project</h1>
    <p>This is the project with ID: {{ projectId }}</p>
  </div>
  -->
  <div class="flex h-screen">
    <ResearchChatWindow class="w-1/2" />
    <PdfViewer class="w-1/2" />
  </div>
</template>

<script>
  import ResearchChatWindow from './ResearchChatWindow.vue';
  import PdfViewer from './PDFViewer.vue';

  
export default {
  props: ['projectId'],
  components: {
    ResearchChatWindow,
    PdfViewer
  }
}
</script>

<style scoped>
  /* Add your styles here */
  @import url("../assets/output.css");
</style>
