import { backend_post, backend_upload } from '@/services/cognito-auth';
import { server } from '@/constants.js';
import * as zeit from '@/services/zeit.js';


export async function new_card_import(front,back) {
    back === undefined ? "" : back;
    
    console.log(`Create New Card ${front} ${back}`)


    let newFront = {ops: [{insert:front}]}
    let newBack = {ops: [{insert:back}]}
    let key =  Date.now()
    let new_card = { }
    
    new_card.jsondata = {}
    new_card.scores = []
    new_card.tags = ""
    
    console.log(`newFront ${newFront} newback ${newBack}`)



    new_card.due_date =   zeit.get_date_string(zeit.toLocalTime(zeit.todays_date()))
    //new_card.due_date =  "2025-01-01 00:00:00"

    new_card.jsondata.front = newFront
    new_card.jsondata.back = newBack
    
    new_card.jsondata.key = key


    new_card.jsondata = JSON.stringify(new_card.jsondata)

    let r = await backend_post(`${server}/cards/add`,new_card)
    console.log(r)



  }

export async function  new_card(front,back,canvas) {


    
    console.log("Create New Card")

    canvas.toBlob(async function(blob) {

	const formData = new FormData();
	
	let newFront = {ops: [{insert:front}]}
	let newBack = {ops: [{insert:back}]}
	let key =  Date.now()
	let new_card = { }

	new_card.jsondata = {}
	new_card.scores = []
	new_card.tags = ""
    
	console.log(`newFront ${newFront} newback ${newBack}`)
	formData.append('file', blob, key);

	//upload image


	new_card.due_date =   zeit.get_date_string(zeit.toLocalTime(zeit.todays_date()))

	new_card.jsondata.front = newFront
	new_card.jsondata.back = newBack
	
	new_card.jsondata.key = key


	new_card.jsondata = JSON.stringify(new_card.jsondata)

	let r = await backend_post(`${server}/cards/add`,new_card)
	console.log(r)

	let ur = await backend_upload(`${server}/upload/card/${r.data.id}`,formData)
	console.log(ur.data.path)
	//new_card.jsondata.path = ur.data.path


     })
  }

export function daysTillNext( x, a = 6.0, b = -0.8, c = 0.28, d = 0.02, theta = 0.2) {
    if (x[x.length - 1] < 4) {
	return 1
    }
    
    const history = x
    
    // Calculate latest correctness streak
    let streak = 0
    for (let i = 0; i < history.length; i++) {
	if (history[i] > 3) {
	    streak++
	} else {
	    break
	}
    }
    
    // Sum up the history
    const historySum = history.reduce(
	(prev, val) => prev + (b + (c * val) + (d * val * val)),
	0
    )
    
    return a * Math.pow(Math.max(1.3, 2.5 + historySum), theta * streak)
}
