<template>
  <div class="w-64 bg-white p-6">
    <!-- General Links -->
    <div>
      <h2 class="font-semibold text-xl mb-4 border-b pb-2">Cards</h2>
      <ul>
	<li class="my-2" v-if="featureFlags.assist" >
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/assist" exact>Assist</router-link>
        </li>

        <li class="my-2" v-if="featureFlags.quiz" >
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/quiz" exact>Quiz</router-link>
        </li>	
        <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :exact-active-class="activeLinkClass" to="/cards" exact>Review</router-link>
        </li>
        <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/cards/new" exact>New Card</router-link>
        </li>
        <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/cards/view" exact>View All</router-link>
        </li>
        <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/cards/stats" exact>Stats</router-link>
        </li>
	
        <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/cards/import" exact>Import</router-link>
        </li>
	
        <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/cards/help" exact>Help</router-link>
        </li>
	<li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/signout" exact>Sign Out</router-link>
        </li>
        <!-- Add more general links here -->
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { get_token } from '@/services/cognito-auth';
import { mapState } from 'vuex';

export default {
  data() {
    return {

	activeLinkClass: "font-bold border-l-4 border-blue-600 pl-2"
    };
  },
    methods: {
	
   
	
  },    
    async mounted() {

	console.log("Mounted")
	//console.log("featureFlags:",this.featureFlags)
	//console.log("featureFlags:",this.isLoggedIn)

	console.log("S1:",this.$store.state.isLoggedIn)
	console.log("S2:",this.$store.state.featureFlags)
	
  
    },
    computed: {
	featureFlags() {
	    return this.$store.state.featureFlags
	//...mapState(['featureFlags'])
	    //...mapState(['isLoggedIn'])
	    }
  }


}
</script>
