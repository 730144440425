<template>
  <div class="p-4">
    <h2 class="text-lg font-bold mb-2">TS Name</h2>
    <div class="mb-4">
      <input 
        type="text" 
        v-model="projectName" 
        class="border rounded p-2 w-full" 
        placeholder="Enter ts name"
      />
    </div>
    <button 
      @click="saveProjectName" 
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Save
    </button>
  </div>
</template>

    <script>


import { server } from '@/constants.js';
import { backend_post } from '@/services/cognito-auth';
export default {
  data() {
    return {
      projectName: ''
    };
  },
  methods: {
    async saveProjectName() {
      // Handle the project name saving logic here
	console.log("Project Name Saved:", this.projectName);

	let ts = {name:  this.projectName}
	
	let response = await backend_post(`${server}/ts/new`, ts)
	console.log(response.data);	    

// You might want to send this data to a server or store it locally

    }
  }
};
</script>

<style scoped>
/* Add any additional styling you need here */
</style>
