
<template>
  <div class="flex h-screen">
    <!-- Side Navigation -->
    <TasksSideNav @changeComponent="changeComponent" class="w-1/4 bg-gray-100 border-r" />

<Calendar  />


<router-view></router-view>

  </div>
</template>

<style scoped>
/* Additional styles if needed */
</style>


<script>
import TasksSideNav from './TasksSideNav.vue'

import { Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';


export default {
  components: {
      TasksSideNav,
Calendar,
    DatePicker,
  },
  data() {
    return {
      date: new Date(),
    };
  },
  methods: {
    
  }
}
</script>
<style>
  @import url("../assets/output.css");

</style>
