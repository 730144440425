


export function draw_screen_bg(canvas,ctx) {

    const { width, height } = canvas.getBoundingClientRect();
 ctx.beginPath();
    ctx.rect(0, 0, width, height);

    
    //ctx.fillStyle = "grey";
//"rgb(255, 165, 0)";
 ctx.fillStyle  =  "rgb(51, 65, 85)";//`rgb(51,65,85, 0)`;
ctx.fill();


}



function draw_screen(ctx) {

 ctx.beginPath();
ctx.rect(20, 20, 150, 100);
ctx.fillStyle = "white";
ctx.fill();


}

export function clear_rect(canvas,ctx) {

    const { width, height } = canvas.getBoundingClientRect();
    ctx.clearRect(0, 0, width, height);
}

function draw_rect(ctx, start_x, start_y, width, height, color) {
    ctx.beginPath();
    ctx.rect(start_x, start_y, width, height);
    ctx.fillStyle = color
    ctx.fill();
 
}

export function draw_image(ctx,path,h,w) {
    var img = new Image;
    img.onload = function() {
	ctx.drawImage(img, 0,0,h,w);

    }

    img.src = path
}


function draw_circle(ctx,center_x,center_y,start_angle,end_angle,color,line_width = 3) {

    console.log("Draw circle")
    /*
    ctx.beginPath()
    //ctx.arc(center_x, center_y, 70, start_angle, end_angle)
    ctx.fillStyle = "white"
    ctx.arc(100, 75, 50, 0, 2 * Math.PI);
    // ctx.fill()
    ctx.stroke();
    */
    
      var radius = 5;

      ctx.beginPath();
      ctx.arc(center_x, center_y, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = color//'black';
      ctx.fill();    
      ctx.lineWidth = line_width;
      ctx.strokeStyle = color//'#000000';
      ctx.stroke();




}


function draw_line(ctx,start_x,start_y,end_x,end_y,line_width,stroke_style) {

    ctx.beginPath()
    ctx.moveTo(start_x,start_y)
    
    ctx.lineTo(end_x, end_y)

    ctx.lineWidth = line_width
//    ctx.strokeStyle = stroke_style//'#ff0000'
    
      ctx.stroke();



}

function draw_line_v(ctx,start_v,end_v,line_width,stroke_style) {
    [start_x,start_y] = start_v
    console.log(start_y)


    return draw_line(ctx,start_x,start_y,end_v[0],end_v[1],line_width,stroke_style)
}


function draw_line_a(ctx,xys,line_width, stroke_style) {
    if (xys.length < 2)
	return
    draw_line(ctx,xys[0][0],xys[0][1],xys[1][0],xys[1][1],line_width, stroke_style)
}


function draw_triangle(ctx,xys,fill_color) {

    ctx.beginPath();
ctx.moveTo(xys[0][0], xys[0][1]);
ctx.lineTo(xys[1][0], xys[1][1]);
ctx.lineTo(xys[2][0], xys[2][1]);
ctx.closePath();

// the outline
ctx.lineWidth = 5
ctx.strokeStyle = '#666666';
ctx.stroke();


    console.log("COLOR:"+ fill_color)
// the fill color
ctx.fillStyle = fill_color//"#FFCC00";
ctx.fill();

}

function draw_text(ctx, text, x, y) {
    ctx.font = "18px sans-serif";
    //ctx.strokeStyle = "black"; // set stroke color to red
    
    //ctx.lineWidth = "1.5";  //  set stroke width to 1.5
  
  
    //ctx.strokeText(text, x, y);
    ctx.fillStyle = "black"
    ctx.fillText(text,x,y)
  

}


export function load_image(ctx,canvas,url) {

    // Create a new image object
    const img = new Image();

    img.crossOrigin = "anonymous"; // Request CORS access
    // Set up the image once it loads
    img.onload = function() {
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };


    // Set the source of the image
    img.src = url;
}
