<template>
  <div class="p-8 rounded-lg shadow-md">
    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Preview</h1>

  <div class="line-clamp-4" v-html="noteHtml"></div>

  </div>
</template>


<script>
import axios from 'axios';
import { get_token,backend_get,backend_upload } from '@/services/cognito-auth';
import * as zeit from '@/services/zeit.js';
import { server } from '@/constants.js';

function addTailwindClassesToHtml(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Add classes to <p> tags
  doc.querySelectorAll('p').forEach(p => {
    p.classList.add('mb-4');  // Example: Add margin-bottom
  });

    //list-decimal
    
    // Add classes to <li> tags
    /*
  doc.querySelectorAll('li').forEach(li => {
    li.classList.add('list-decimal', 'ml-5');  // Example: Add list style and margin-left
    });
    */

      doc.querySelectorAll('ol').forEach(ol => {
    ol.classList.add('list-decimal');  // Example: Set list style position inside
      });
    
  // Add classes to <ul> tags
  doc.querySelectorAll('ul').forEach(ul => {
    ul.classList.add('list-disc');  // Example: Set list style position inside
  });

  // Add classes to <h1> tags
  doc.querySelectorAll('h1').forEach(h1 => {
    h1.classList.add('text-4xl', 'font-bold', 'mb-4');  // Example: Set font size, font weight, and margin-bottom
  });

  // Add classes to <h2> tags
  doc.querySelectorAll('h2').forEach(h2 => {
    h2.classList.add('text-3xl', 'font-semibold', 'mb-3');  // Example: Set font size, font weight, and margin-bottom
  });

  // Convert the modified document back to a string
  return doc.body.innerHTML;
}



export default {
    props: {
	noteId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },    

  data() {
    return {
	note: null,
	noteHtml: ""
    };
  },
  methods: {
    

      

  },
          async mounted() {

console.log("Load Preview")

	let url = `${server}/messages/get/${this.noteId}`
	let r  = await backend_get(url)

	      this.note = r.data
	      this.noteHtml = addTailwindClassesToHtml(this.note.message.text)
	      console.log(this.noteHTML)
//	      this.get_stats()
	  }
}

</script>
