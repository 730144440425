

import axios from 'axios';

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
var userPool = null
var poolData = {
    UserPoolId: 'us-east-1_CstKJEVhW',
    ClientId:'7ntup34r3l866b9etkks20qc7u'
};

export function auth_init() {
    userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    if (typeof AWSCognito !== 'undefined') {
        //AWSCognito.config.region =  'us-east-1'
    } 
    console.log(userPool)
}

function createCognitoUser(email) {
    return new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool
    });
}



export function login(email,password,onSuccess,onFailure) {

        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: email,
            Password: password
        });


        var cognitoUser = createCognitoUser(email);
	console.log("AUTH:",cognitoUser)
        cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
	onSuccess(session)},
        onFailure: onFailure,
	})

}

export function logout() {
    userPool.getCurrentUser().signOut();
}


export function register_code(email, password, code, cb) {

    var attributeList = [];
    
        var dataEmail = {
            Name: 'email',
            Value: email
        };

    const dataInviteCode = {
	Name: 'custom:invitecode',
	Value: code
    };

    const attributeInviteCode = new AmazonCognitoIdentity.CognitoUserAttribute(dataInviteCode);
    attributeList.push(attributeInviteCode);

    
    var dataCode = {
	Name: 'custom:invitecode',
	Value: code
    };
    
    var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);


    attributeList.push(attributeEmail);

    
    userPool.signUp(email, password,attributeList, null,
		    function signUpCallback(err, result) {
			cb(result,err)

            }
        );
}

export function forgot_password_send(email) {
    var cognitoUser = createCognitoUser(email);
    console.log("SEND FORGET PASSWORD")
    
    cognitoUser.forgotPassword({
	onSuccess: function(data) {
		// successfully initiated reset password request
		console.log('from forgotPassword: ' + data);
	},
	onFailure: function(err) {
		alert(err.message || JSON.stringify(err));
	},

})

}

export function update_password(email,code, new_password) {
    var cognitoUser = createCognitoUser(email);
    
 cognitoUser.confirmPassword(code, new_password, {
        onSuccess: function () {
            //callback(null, 'Password reset successful.');
	    console.log('Password reset successful.')
        },
        onFailure: function (err) {
            //callback(err);
        }
    });

}

export function get_token() {

    var cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser)
	return null

    return new Promise(function(resolve) {
	cognitoUser.getSession(function sessionCallback(err, session) {
            if (err) {
		console.log("ERROR",err.message)
		window.location.href = `/login`
            } else if (!session.isValid()) {
		console.log("SESSION IS SAD")
		
            } else {
		resolve(session.getIdToken().getJwtToken())
	    }})
    })    

        

    
}

export function current_user() {
    return userPool.getCurrentUser()
}


export async function backend_post(url,body) {

    	const config = {
	    headers:{
    		"Authorization": await get_token(),

	    }
	    
	    
	};

      return await axios.post(url, body,config)
}

export async function backend_upload(url,data) {
    console.log("AWAIT POST:",url,data)

    const token = await get_token();

    
    return await axios.post(url, data, {
            headers: {
                'Authorization': token,
                'Content-Type': 'multipart/form-data'
            }
    })

}

export async function backend_get(url) {

    	const config = {
	    headers:{
    		"Authorization": await get_token(),

	    }
	    
	    
	};

      return await axios.get(url,config)
}

export async function backend_delete(url) {

    const token = await get_token();
    
    axios.delete(url, {
	headers: {
            "Authorization": token
	}
    })
	.then((response) => {
	    console.log("DELETE");
	    // Do something with the result
	})
	.catch((error) => {
	    console.error("Error:", error);
	});

}

/*
import jwtDecode from "./jwt-decode.esm.js";

window._config = {
    cognito: {
        userPoolId: 'us-east-1_CstKJEVhW', 
        userPoolClientId: '7ntup34r3l866b9etkks20qc7u', // e.g. 25ddkmj4v6hfsfvruhpfi7n4hv
        region: 'us-east-1' // e.g. us-east-2
    },
    
};




var userPool = null
var poolData = {
        UserPoolId: _config.cognito.userPoolId,
        ClientId: _config.cognito.userPoolClientId
};

export function auth_init() {

    console.log(poolData)
    if (!(_config.cognito.userPoolId &&
          _config.cognito.userPoolClientId &&
          _config.cognito.region)) {
        $('#noCognitoMessage').show();
        return;
    }

    userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    if (typeof AWSCognito !== 'undefined') {
        AWSCognito.config.region = _config.cognito.region;
    } 
    }
    */

/*









export function reset_password() {

    cognitoUser.completeNewPasswordChallenge(new_password, requiredAttributes, {
		    onSuccess: result => {
			console.log("new password set")
			// login
		    }})
}


}

export function forgot_password_update(email, password, code,cb) {
    
    var cognitoUser = createCognitoUser(email);


		cognitoUser.confirmPassword(code, password, {
			onSuccess() {
			    console.log('Password confirmed!');
			    cb()
			},
			onFailure(err) {
				console.log('Password not confirmed!');
			},
		});

    
}





*/
/*















*/
