<template>
  <div class="p-6 bg-gray-100 max-w-full">
    <h1 class="text-2xl font-bold mb-6">Quiz</h1>

   <div v-if="showStats" class="mb-6 bg-white p-4 rounded shadow">
      <h2 class="text-xl mb-4">Quiz Stats</h2>
      <p><strong>Percentage Correct:</strong> {{ percentageCorrect }}%</p>
    </div>

    <div v-for="(question, index) in questions" :key="index" class="mb-6">
<!--
    <component :is="getComponentForQuestionType(question.type)" :question="question.question"></component>
    -->

    <div v-if="question.correct" class="mt-4 text-center text-green-500">
    <font-awesome-icon icon="fa-solid fa-circle-check" />
    </div>
    <div v-if="question.incorrect" class="mt-4 text-center text-red-500">
    <font-awesome-icon icon="fa-solid fa-circle-xmark" />
    </div>

<QuestionTextInput
        :question="question.question"
        ref="textInputQuestions"
      />

      <div v-if="question.answer" class="mt-4 text-center text-green-500">
	{{ question.answer }}
	{{ question.explain}}
 
</div>
   
    </div>

    <button @click="submitAnswers" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Submit
    </button>
  </div>
</template>

    <script>
import { get_token,backend_post, backend_get} from '@/services/cognito-auth';
import { server } from '@/constants.js';

import QuestionTextInput from './QuestionTextInput.vue';

export default {
 props: {
	quizId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },    
    components: {

    QuestionTextInput
  },
  data() {
    return {
      questions: [
        
        // Add more questions as needed
      ],
	selectedAnswers: [],
	showStats: false,
	stats_p: 0

    };
  },
  methods: {
    submitAnswers() {
      console.log("Selected Answers:", this.selectedAnswers);
      // Handle the submission logic here, e.g., send answers to the server
    },
      getComponentForQuestionType(type) {
	  return 'QuestionTextInput';
	  /*
	  switch (type) {
	  case 'textInput':
              return 'QuestionTextInput';
	  default:
              return null; // or a default component
	      }
	      */
      },
      submitAnswers() {
      const answers = {};

	  console.log(this.$refs.textInputQuestions)
	  // Loop through each TextInputQuestion component
	  let correct = 0
	  
      this.$refs.textInputQuestions.forEach((component, index) => {
        const questionId = this.questions[index].question.id;
          answers[questionId] = component.userAnswer;
	  let ca = this.questions[questionId].a
	  
	  this.questions[questionId].answer = this.questions[questionId].a
	  this.questions[questionId].explain = this.questions[questionId].e

	  if (component.userAnswer == ca) {
	      this.questions[questionId].correct = true
	      correct+=1
	  }
	  else
	      this.questions[questionId].incorrect = true
	  
	  console.log(this.questions[questionId])
      });

	  this.stats_p = (correct / this.questions.length) * 100
	 this.showStats = true
      console.log(answers);
      // Now you can send 'answers' to your backend or process it further
    }
  
    
  },
    computed: {
	percentageCorrect() {
	    return this.stats_p
	}
    },

    async mounted() {

	console.log("Mounted:",this.quizId)

		let url = `${server}/quiz/get/${this.quizId}`
	let r  = await backend_get(url)
	console.log(r.data.quiz)

	
	r.data.quiz.questions.forEach((q,i) => {
		    this.questions.push({ type: 'textInput', a: q.answer, e: q.explain, question: {id: i, prompt: q.question}})
		})
	/*
	 fetch(`/da-wo.json`)
	   .then(response => response.json())
	    .then(data => {
		console.log("Data",data)

		data.questions.forEach((q,i) => {
		    this.questions.push({ type: 'textInput', a: q.a, question: {id: i, prompt: q.q}})
		})
		})
		*/


    }
};
</script>

<style scoped>
/* Add any additional styles specific to this component */
</style>
